import React,{useMemo} from 'react'
import { useParams } from 'react-router-dom';
import Header from '../components/Header'
import DataTable from 'react-data-table-component';
import { MdCreate,MdOutlineViewWeek } from "react-icons/md";
import { Link } from "react-router-dom";
import Client,{CachedClient} from '../client'
import {gql} from '@apollo/client';
import {StringToColour} from "../Utils";
import { Input } from 'reactstrap'
import {SearchEntity, queriesByID, searchFields} from '../components/SearchEntity'
import { GPGModal } from '../components/GPGModal';
import { usePopupManager } from "react-popup-manager";

const columns = [
    
    {
        name: 'ID',        
        
        selector: row => row.id,
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "id",  
        omit:false,      
    },
    {
        name: 'Name',        
        
        selector: row => row.name,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "name",  
        omit:false,      
    },
    {
        name: 'Number',        
        
        selector: row => row.number,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "number",  
        omit:false,      
    },
    {
        name: 'Slug',        
        
        selector: row => row.slug,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "slug",  
        omit:false,      
    },
    {
        name: 'Description',        
        
        selector: row => row.description,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "description",  
        omit:false,      
    },
    {
        name: 'Main Image',        
        
        selector: row => row.main_image,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "main_image",  
        omit:false,      
    },
    {
        name: 'Air Date',        
        
        selector: row => row.air_date,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "air_date",  
        omit:false,      
    },
    {
        name: 'Guest Judge',        
        
        selector: row => row.guest_judge,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "guest_judge",  
        omit:false,      
    },
    {
        name: 'Episode Type',        
        
        selector: row => row.episode_type,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "episode_type",  
        omit:false,      
    },
    {
        name: 'Mini Challenge',        
        
        selector: row => row.mini_challenge_name,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "mini_challenge_name",  
        omit:false,      
    },
    {
        name: 'Maxi Challenge',        
        
        selector: row => row.maxi_challenge_name,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "maxi_challenge_name",  
        omit:false,      
    },
    {
        name: 'Maxi Challenge Media',        
        
        selector: row => "JSON Object",
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "challenge_media",  
        omit:false,      
    },
    {
        name: 'Runway Theme',        
        
        selector: row => row.runway_theme,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "runway_theme",  
        omit:false,      
    },
    {
        name: 'Lipsync',        
        
        selector: row => "JSON Object",
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "lipsync",  
        omit:false,      
    },
    {
        name: 'Status',        
        
        selector: row => row.status,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "status",  
        omit:false,      
    },
    {
        name: 'Created',        
        
        selector: row => row.created_at,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "created_at",  
        omit:false,      
    },
    {
        name: 'Season',        
        
        selector: row => {            
            return row.season_id_desc ? row.season_id_desc : row.season_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "season_id",  
        omit:false,      
    },
    {
        name: 'Mini Challenge Winner',        
        
        selector: row => {            
            return row.mini_winner_id_desc ? row.mini_winner_id_desc : row.mini_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "mini_winner_id",  
        omit:false,      
    },
    {
        name: 'Second Mini Challenge Winner',        
        
        selector: row => {            
            return row.second_mini_winner_id_desc ? row.second_mini_winner_id_desc : row.second_mini_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "second_mini_winner_id",  
        omit:false,      
    },
    {
        name: 'Third Mini Challenge Winner',        
        
        selector: row => {            
            return row.third_mini_winner_id_desc ? row.third_mini_winner_id_desc : row.third_mini_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "third_mini_winner_id",  
        omit:false,      
    },
    {
        name: 'Fourth Mini Challenge Winner',        
        
        selector: row => {            
            return row.fourth_mini_winner_id_desc ? row.fourth_mini_winner_id_desc : row.fourth_mini_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "fourth_mini_winner_id",  
        omit:false,      
    },
    {
        name: 'Fifth Mini Challenge Winner',        
        
        selector: row => {            
            return row.fifth_mini_winner_id_desc ? row.fifth_mini_winner_id_desc : row.fifth_mini_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "fifth_mini_winner_id",  
        omit:false,      
    },
    {
        name: 'Sixth Mini Challenge Winner',        
        
        selector: row => {            
            return row.sixth_mini_winner_id_desc ? row.sixth_mini_winner_id_desc : row.sixth_mini_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "sixth_mini_winner_id",  
        omit:false,      
    },
    {
        name: 'Winner',        
        
        selector: row => {            
            return row.winner_id_desc ? row.winner_id_desc : row.winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "winner_id",  
        omit:false,      
    },
    {
        name: 'Second Winner',        
        
        selector: row => {            
            return row.second_winner_id_desc ? row.second_winner_id_desc : row.second_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "second_winner_id",  
        omit:false,      
    },
    {
        name: 'Third Winner',        
        
        selector: row => {            
            return row.third_winner_id_desc ? row.third_winner_id_desc : row.third_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "third_winner_id",  
        omit:false,      
    },
    {
        name: 'Fourth Winner',        
        
        selector: row => {            
            return row.fourth_winner_id_desc ? row.fourth_winner_id_desc : row.fourth_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "fourth_winner_id",  
        omit:false,      
    },
    {
        name: 'Fifth Winner',        
        
        selector: row => {            
            return row.fifth_winner_id_desc ? row.fifth_winner_id_desc : row.fifth_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "fifth_winner_id",  
        omit:false,      
    },
    {
        name: 'Sixth Winner',        
        
        selector: row => {            
            return row.sixth_winner_id_desc ? row.sixth_winner_id_desc : row.sixth_winner_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "sixth_winner_id",  
        omit:false,      
    },
    {
        name: 'Eliminated',        
        
        selector: row => {            
            return row.eliminated_id_desc ? row.eliminated_id_desc : row.eliminated_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "eliminated_id",  
        omit:false,      
    },
    {
        name: 'Second Eliminated',        
        
        selector: row => {            
            return row.second_eliminated_id_desc ? row.second_eliminated_id_desc : row.second_eliminated_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "second_eliminated_id",  
        omit:false,      
    },
    {
        name: 'Special Elimination Message',        
        
        selector: row => row.elimination_msg,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "elimination_msg",  
        omit:false,      
    },
    {
        name: 'User',        
        
        selector: row => row.user_id,
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "user_id",  
        omit:false,      
    },    
];

var pressedKeys = {};

class Episode extends React.Component {
    constructor(props) {
        super(props)        
        this.state = { 
            pending:true,           
            items: [],
            pageSize:14,
            page:0,
            total: 10000,
            searchQuery:"",
            selectRefs: {},
            columns: [],            
        }

        
            
                this.state.selectRefs.season = React.createRef();
            
        
            
                this.state.selectRefs.drag = React.createRef();
            
        
    }

    componentDidMount() {        
        this.fetchItems();

        this.state.columns = [
            
            {
                identifier: "id",
                name: 'ID',        
                
                selector: row => row.id,
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "id",  
                omit:false,      
            },
            {
                identifier: "name",
                name: 'Name',        
                
                selector: row => row.name,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "name",  
                omit:false,      
            },
            {
                identifier: "number",
                name: 'Number',        
                
                selector: row => row.number,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "number",  
                omit:false,      
            },
            {
                identifier: "slug",
                name: 'Slug',        
                
                selector: row => row.slug,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "slug",  
                omit:false,      
            },
            {
                identifier: "description",
                name: 'Description',        
                
                selector: row => row.description,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "description",  
                omit:false,      
            },
            {
                identifier: "main_image",
                name: 'Main Image',        
                
                selector: row => row.main_image,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "main_image",  
                omit:false,      
            },
            {
                identifier: "air_date",
                name: 'Air Date',        
                
                selector: row => row.air_date,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "air_date",  
                omit:false,      
            },
            {
                identifier: "guest_judge",
                name: 'Guest Judge',        
                
                selector: row => row.guest_judge,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "guest_judge",  
                omit:false,      
            },
            {
                identifier: "episode_type",
                name: 'Episode Type',        
                
                selector: row => row.episode_type,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "episode_type",  
                omit:false,      
            },
            {
                identifier: "mini_challenge_name",
                name: 'Mini Challenge',        
                
                selector: row => row.mini_challenge_name,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "mini_challenge_name",  
                omit:false,      
            },
            {
                identifier: "maxi_challenge_name",
                name: 'Maxi Challenge',        
                
                selector: row => row.maxi_challenge_name,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "maxi_challenge_name",  
                omit:false,      
            },
            {
                identifier: "challenge_media",
                name: 'Maxi Challenge Media',        
                
                selector: row => "JSON Object",
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "challenge_media",  
                omit:false,      
            },
            {
                identifier: "runway_theme",
                name: 'Runway Theme',        
                
                selector: row => row.runway_theme,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "runway_theme",  
                omit:false,      
            },
            {
                identifier: "lipsync",
                name: 'Lipsync',        
                
                selector: row => "JSON Object",
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "lipsync",  
                omit:false,      
            },
            {
                identifier: "status",
                name: 'Status',        
                
                selector: row => row.status,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "status",  
                omit:false,      
            },
            {
                identifier: "created_at",
                name: 'Created',        
                
                selector: row => row.created_at,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "created_at",  
                omit:false,      
            },
            {
                identifier: "season_id",
                name: 'Season',        
                
                selector: row => {            
                    return row.season_id_desc ? row.season_id_desc : row.season_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "season_id",  
                omit:false,      
            },
            {
                identifier: "mini_winner_id",
                name: 'Mini Challenge Winner',        
                
                selector: row => {            
                    return row.mini_winner_id_desc ? row.mini_winner_id_desc : row.mini_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "mini_winner_id",  
                omit:false,      
            },
            {
                identifier: "second_mini_winner_id",
                name: 'Second Mini Challenge Winner',        
                
                selector: row => {            
                    return row.second_mini_winner_id_desc ? row.second_mini_winner_id_desc : row.second_mini_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "second_mini_winner_id",  
                omit:false,      
            },
            {
                identifier: "third_mini_winner_id",
                name: 'Third Mini Challenge Winner',        
                
                selector: row => {            
                    return row.third_mini_winner_id_desc ? row.third_mini_winner_id_desc : row.third_mini_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "third_mini_winner_id",  
                omit:false,      
            },
            {
                identifier: "fourth_mini_winner_id",
                name: 'Fourth Mini Challenge Winner',        
                
                selector: row => {            
                    return row.fourth_mini_winner_id_desc ? row.fourth_mini_winner_id_desc : row.fourth_mini_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "fourth_mini_winner_id",  
                omit:false,      
            },
            {
                identifier: "fifth_mini_winner_id",
                name: 'Fifth Mini Challenge Winner',        
                
                selector: row => {            
                    return row.fifth_mini_winner_id_desc ? row.fifth_mini_winner_id_desc : row.fifth_mini_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "fifth_mini_winner_id",  
                omit:false,      
            },
            {
                identifier: "sixth_mini_winner_id",
                name: 'Sixth Mini Challenge Winner',        
                
                selector: row => {            
                    return row.sixth_mini_winner_id_desc ? row.sixth_mini_winner_id_desc : row.sixth_mini_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "sixth_mini_winner_id",  
                omit:false,      
            },
            {
                identifier: "winner_id",
                name: 'Winner',        
                
                selector: row => {            
                    return row.winner_id_desc ? row.winner_id_desc : row.winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "winner_id",  
                omit:false,      
            },
            {
                identifier: "second_winner_id",
                name: 'Second Winner',        
                
                selector: row => {            
                    return row.second_winner_id_desc ? row.second_winner_id_desc : row.second_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "second_winner_id",  
                omit:false,      
            },
            {
                identifier: "third_winner_id",
                name: 'Third Winner',        
                
                selector: row => {            
                    return row.third_winner_id_desc ? row.third_winner_id_desc : row.third_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "third_winner_id",  
                omit:false,      
            },
            {
                identifier: "fourth_winner_id",
                name: 'Fourth Winner',        
                
                selector: row => {            
                    return row.fourth_winner_id_desc ? row.fourth_winner_id_desc : row.fourth_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "fourth_winner_id",  
                omit:false,      
            },
            {
                identifier: "fifth_winner_id",
                name: 'Fifth Winner',        
                
                selector: row => {            
                    return row.fifth_winner_id_desc ? row.fifth_winner_id_desc : row.fifth_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "fifth_winner_id",  
                omit:false,      
            },
            {
                identifier: "sixth_winner_id",
                name: 'Sixth Winner',        
                
                selector: row => {            
                    return row.sixth_winner_id_desc ? row.sixth_winner_id_desc : row.sixth_winner_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "sixth_winner_id",  
                omit:false,      
            },
            {
                identifier: "eliminated_id",
                name: 'Eliminated',        
                
                selector: row => {            
                    return row.eliminated_id_desc ? row.eliminated_id_desc : row.eliminated_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "eliminated_id",  
                omit:false,      
            },
            {
                identifier: "second_eliminated_id",
                name: 'Second Eliminated',        
                
                selector: row => {            
                    return row.second_eliminated_id_desc ? row.second_eliminated_id_desc : row.second_eliminated_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "second_eliminated_id",  
                omit:false,      
            },
            {
                identifier: "elimination_msg",
                name: 'Special Elimination Message',        
                
                selector: row => row.elimination_msg,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "elimination_msg",  
                omit:false,      
            },
            {
                identifier: "user_id",
                name: 'User',        
                
                selector: row => row.user_id,
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "user_id",  
                omit:false,      
            },    
        ]   

        var cachedColumns = localStorage.getItem("episode_columns")     
        var parsedCachedColumns = JSON.parse(cachedColumns)        
        if (parsedCachedColumns !== undefined && parsedCachedColumns !== null) {            
            this.state.columns.map((column, index) => {                
                this.state.columns[index].omit = parsedCachedColumns[column.identifier]
            })
        }        
        this.setState(this.state)

        window.onkeyup = function(e) { pressedKeys[e.keyCode] = false; }
        window.onkeydown = function(e) { pressedKeys[e.keyCode] = true; }

    }

    fetchItems() {        
        const offset = this.state.page*this.state.pageSize        
        Client
            .query({
                query: gql`
                query EpisodeByStatus($limit: Int, $offset: Int) {
                    EpisodeByStatus(status:"enabled",limit:$limit, offset:$offset){ 
                        id,
                        name,
                        number,
                        slug,
                        description,
                        main_image,
                        air_date,
                        guest_judge,
                        episode_type,
                        mini_challenge_name,
                        maxi_challenge_name,
                        challenge_media{
                            name,description,video_url,image_url,
                        },
                        runway_theme,
                        lipsync{
                            song_name,song_artist,song_url,drag_id,drag2_id,drag3_id,drag4_id,drag5_id,drag6_id,order,video_url,no_looser,no_single_winner,
                        },
                        status,
                        created_at,
                        season_id,
                        mini_winner_id,
                        second_mini_winner_id,
                        third_mini_winner_id,
                        fourth_mini_winner_id,
                        fifth_mini_winner_id,
                        sixth_mini_winner_id,
                        winner_id,
                        second_winner_id,
                        third_winner_id,
                        fourth_winner_id,
                        fifth_winner_id,
                        sixth_winner_id,
                        eliminated_id,
                        second_eliminated_id,
                        elimination_msg,
                        user_id,
                    }
                }
                `,
                variables:{
                    limit:this.state.pageSize,
                    offset:offset
                }
            })
            .then((result) => {
                
                const items = result.data.EpisodeByStatus
                if (items.length < this.state.pageSize){
                    this.state.total = ((this.state.page)*this.state.pageSize)+items.length
                }                
                                       
                Promise.allSettled(EntityRefPromises(items)).then(()=>{
                    setTimeout(() => {                    
                        this.state.pending = false
                        this.state.items = items                        
                        this.setState(this.state)
                    }, 500)
                })
            });
            
    }

    searchItems() {        
        const offset = this.state.page*this.state.pageSize        
        Client
            .query({
                query: gql`
                query SearchEpisode($query:String!, $limit: Int, $offset: Int) {
                    SearchEpisode(query:$query,limit:$limit, offset:$offset){ 
                        id,
                        name,
                        number,
                        slug,
                        description,
                        main_image,
                        air_date,
                        guest_judge,
                        episode_type,
                        mini_challenge_name,
                        maxi_challenge_name,
                        challenge_media{
                            name,description,video_url,image_url,
                        },
                        runway_theme,
                        lipsync{
                            song_name,song_artist,song_url,drag_id,drag2_id,drag3_id,drag4_id,drag5_id,drag6_id,order,video_url,no_looser,no_single_winner,
                        },
                        status,
                        created_at,
                        season_id,
                        mini_winner_id,
                        second_mini_winner_id,
                        third_mini_winner_id,
                        fourth_mini_winner_id,
                        fifth_mini_winner_id,
                        sixth_mini_winner_id,
                        winner_id,
                        second_winner_id,
                        third_winner_id,
                        fourth_winner_id,
                        fifth_winner_id,
                        sixth_winner_id,
                        eliminated_id,
                        second_eliminated_id,
                        elimination_msg,
                        user_id,
                    }
                }
                `,
                variables:{
                    query:this.state.searchQuery,
                    limit:this.state.pageSize,
                    offset:offset
                }
            })
            .then((result) => {
                
                const items = result.data.SearchEpisode
                if (items.length < this.state.pageSize){
                    this.state.total = ((this.state.page)*this.state.pageSize)+items.length
                }                
                Promise.allSettled(EntityRefPromises(items)).then(()=>{
                    setTimeout(() => {                    
                        this.state.pending = false
                        this.state.items = items                        
                        this.setState(this.state)
                    }, 500)
                })
            });
    }

    load(){
        if (this.state.searchQuery !== "") {
            this.searchItems()
        } else {
            this.fetchItems()
        }
    }

    

    render() {
        return (
            <div>
                <Header />  
                <div className="actionsBar">                     
                    <div className="searchBar">                    
                        <Input
                            type="text"
                            name="search"
                            onChange={(e) => {
                                this.state.searchQuery = e.target.value
                                this.setState(this.state)
                                this.load()
                            } }
                            placeholder="Search"                                   
                            value={this.state.searchQuery}                                                          
                        />   
                    </div>                                
                    <a onClick={() => {
                                    this.props.popups.open(GPGModal, {
                                                title: <b>Edit Columns</b>,
                                                content: <Columns 
                                                    columns={this.state.columns} 
                                                    onChange={ (columns) => {
                                                        this.state.columns = [ ...columns]
                                                        const omit = {}
                                                        columns.map((column) => {
                                                            omit[column.identifier] = column.omit
                                                        })
                                                        localStorage.setItem("episode_columns", JSON.stringify(omit))
                                                        this.setState(this.state)
                                                    } }
                                                 />,
                                                onClose: (...params) => {
                                                }
                                                }); 
                                }}><MdOutlineViewWeek /> Columns</a>
                    <Link to="/episode/edit"><MdCreate /> Create</Link>                                                         
                </div>
                <div className="filters">
                        
                            
                            <div className="filter">
                                <SearchEntity 
                                    entityIdentifier="season" 
                                    entityName="Season"
                                    onChange={(e, entityIdentifier) => {   
                                        for (const selectRef in this.state.selectRefs) { 
                                            if (selectRef !== entityIdentifier){ 
                                                this.state.selectRefs[selectRef].current.clearValue();
                                            }
                                        }                                        
                                        if (e !== null) {                                   
                                            this.state.searchQuery = e.value
                                            this.setState(this.state)
                                            this.load() 
                                        } else {
                                            this.state.searchQuery = ""
                                            this.setState(this.state)
                                            this.load()                                                                                         
                                        }                                            
                                    } }
                                    placeholder={"Search Season"}
                                    value={this.state.searchQuery}
                                    ref={this.state.selectRefs.season}
                                    />
                            </div>
                            
                        
                            
                            <div className="filter">
                                <SearchEntity 
                                    entityIdentifier="drag" 
                                    entityName="Drag"
                                    onChange={(e, entityIdentifier) => {   
                                        for (const selectRef in this.state.selectRefs) { 
                                            if (selectRef !== entityIdentifier){ 
                                                this.state.selectRefs[selectRef].current.clearValue();
                                            }
                                        }                                        
                                        if (e !== null) {                                   
                                            this.state.searchQuery = e.value
                                            this.setState(this.state)
                                            this.load() 
                                        } else {
                                            this.state.searchQuery = ""
                                            this.setState(this.state)
                                            this.load()                                                                                         
                                        }                                            
                                    } }
                                    placeholder={"Search Drag"}
                                    value={this.state.searchQuery}
                                    ref={this.state.selectRefs.drag}
                                    />
                            </div>
                            
                        
                        <div className="clear"></div>
                    </div>
                <div className="tableWrapper" style={{borderTopColor:StringToColour('episode')}}>                                                             
                <DataTable
                    title="Episode"
                    highlightOnHover="true"
                    pointerOnHover="true"
                    pagination="true"
                    paginationServer="true"
                    paginationPerPage={this.state.pageSize}
                    columns={this.state.columns}
                    data={this.state.items}
                    pending={this.state.pending} 
                    paginationTotalRows={this.state.total}                   
			        onChangePage={(page) => {                            
                            this.state.page = page-1 
                            this.setState(this.state)	
                            this.load()
                        }
                    }
                    onChangeRowsPerPage={(size, page) => {
                            this.state.page = page-1
                            this.state.pageSize = size
                            this.setState(this.state)  
                            this.load()                        
                        }
                    }
                    onRowClicked={(row) => {                             
                            if (pressedKeys[91]) {
                                var url = "/episode/edit/"+row.id;
                                window.open(url, '_blank', 'noopener,noreferrer').focus();
                            } else {
                                window.location.href = "/episode/edit/"+row.id;                           
                            }
                        }
                    }
                />
                </div>
            </div>
        )
    }
}



export default (props) => <Episode {...props}
            params={useParams()} popups={usePopupManager()} />

class Columns extends React.Component {
    constructor(props) {
        super(props)        
        this.state = {             
            columns: props.columns
        }
    }

    render() {
        return (
            <ul className="columnsEdit">
                {this.state.columns.map((column, index) => 
                    <li key={index} >                                                        
                        <Input
                            type="checkbox"
                            name={column.name}
                            onChange={(e) => {                                
                                this.state.columns[index].omit = !e.target.checked
                                this.setState(this.state)
                                this.props.onChange(this.state.columns)
                            } }                                                                                                    
                            value={ !this.state.columns[index].omit }
                            checked={ !this.state.columns[index].omit }                                                                                                
                        />
                        <span>{column.name}</span>
                    </li>
                )}
            </ul>
        )
    }
}


function EntityRefPromises(items) {
     return items.map((item, index) => { 
        var res = [] 
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
            if (item.season_id !== "" && item.season_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("season"),
                        variables:{
                            id:item.season_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["SeasonByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("season")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    season_id_obj:data[0],
                                    season_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.mini_winner_id !== "" && item.mini_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.mini_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    mini_winner_id_obj:data[0],
                                    mini_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.second_mini_winner_id !== "" && item.second_mini_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.second_mini_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    second_mini_winner_id_obj:data[0],
                                    second_mini_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.third_mini_winner_id !== "" && item.third_mini_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.third_mini_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    third_mini_winner_id_obj:data[0],
                                    third_mini_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.fourth_mini_winner_id !== "" && item.fourth_mini_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.fourth_mini_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    fourth_mini_winner_id_obj:data[0],
                                    fourth_mini_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.fifth_mini_winner_id !== "" && item.fifth_mini_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.fifth_mini_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    fifth_mini_winner_id_obj:data[0],
                                    fifth_mini_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.sixth_mini_winner_id !== "" && item.sixth_mini_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.sixth_mini_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    sixth_mini_winner_id_obj:data[0],
                                    sixth_mini_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.winner_id !== "" && item.winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    winner_id_obj:data[0],
                                    winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.second_winner_id !== "" && item.second_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.second_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    second_winner_id_obj:data[0],
                                    second_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.third_winner_id !== "" && item.third_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.third_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    third_winner_id_obj:data[0],
                                    third_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.fourth_winner_id !== "" && item.fourth_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.fourth_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    fourth_winner_id_obj:data[0],
                                    fourth_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.fifth_winner_id !== "" && item.fifth_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.fifth_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    fifth_winner_id_obj:data[0],
                                    fifth_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.sixth_winner_id !== "" && item.sixth_winner_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.sixth_winner_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    sixth_winner_id_obj:data[0],
                                    sixth_winner_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.eliminated_id !== "" && item.eliminated_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.eliminated_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    eliminated_id_obj:data[0],
                                    eliminated_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.second_eliminated_id !== "" && item.second_eliminated_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.second_eliminated_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    second_eliminated_id_obj:data[0],
                                    second_eliminated_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
        
        
        
        return res
    })
}