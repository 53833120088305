import React from 'react'
import { Formik } from 'formik';
import { Card, CardBody, CardTitle, CardText, Form, Label, Input, Button, FormGroup } from 'reactstrap'
import ImageUploading from 'react-images-uploading';
import { GPGModal } from '../components/GPGModal';
import { toast } from 'react-toastify';
import axios from 'axios';
import {StringToColour} from "../Utils";
import FetchUser from '../FetchUser';
import {SearchEntity} from '../components/SearchEntity'



    

    

    

    

    

    

    

    

    

    
import SeasonAiringDetailsForm from '../forms/SeasonAiringDetailsForm';
    

    

    

    

    

    

    

    

    

    


class SeasonForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity: props.entity
        }           

        
                            
                                
            
        
            
        
                            
                                
            
        
                            
                                
            
        
            
        
            
        
            
        
            
        
            
        
            
        
                            
                                
            
        
                            
                                
            
        
                            
                                
            
        
                            
                                
            
        
            
        
            
        
            
        
                            
                                
            
        
            
        
    }

    componentDidMount() {
        FetchUser().then(res => {
            if (res.status === 200) {
                this.state.user = res.data
                this.setState(this.state)
            }
        })
    }

    onImageChange(imageList, addUpdateIndex, fieldIdentifier) {
        // data for submit
        console.log(imageList)
        if (imageList.length > 0) {
            var imageFile = imageList[0]
            var formData = new FormData();            
            formData.append("file", imageFile.file);
            axios.post('https://api.dragium.com/upload', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(response => {                    
                    if (response && response.data && response.data.file_path) {
                        this.state.entity[fieldIdentifier] = response.data.file_path
                        this.setState(this.state)
                    }
                }
            )
        }
    };

    render() {
        return (
            <div className="formWrapper"  style={{borderTopColor:StringToColour('season')}}> 
                    <Formik    
                        initialValues={ this.state.entity }                    
                        validate={values => {
                            const errors = {};   
                            
                            if (!this.state.entity.slug) {
                            
                                errors.slug = "Required";
                            
                            }
                            if (!this.state.entity.number) {
                            
                                errors.number = "Required";
                            
                            }
                            if (!this.state.entity.status) {
                            
                                errors.status = "Required";
                            
                            }
                            if (!this.state.entity.created_at) {
                            
                            }
                            if (!this.state.entity.updated_at) {
                            
                            }
                            if (!this.state.entity.drag_show_id) {
                            
                                errors.drag_show_id = "Required";
                            
                            }
                            if (!this.state.entity.user_id) {
                            
                                errors.user_id = "Required";
                            
                            }                                               
                            return errors;
                        }}  
                        onSubmit={(values, actions) =>{                            
                            this.props.onSubmit(this.state.entity)                                
                        }}                      
                        >
                        {({                            
                            errors,   
                            handleBlur,
                            handleSubmit,
                            isValid,                                                                                 
                        }) => {
                        if (!isValid) {                                
                            toast.error('Error validating form',{
                                position: toast.POSITION.TOP_CENTER,
                                toastId: "season-validation"
                            });                                
                        } else {
                            toast.dismiss("season-validation");
                        }
                        return (                            
                            <form onSubmit={handleSubmit}>
                                
                                                                
                                <FormGroup className="inputWrapper">
                                <span>ID*</span>
                                
                                
                                <Input
                                    type="text"
                                    name="id"
                                    onChange={(e) => {
                                        this.state.entity.id = e.target.value
                                        this.setState(this.state)
                                    } }
                                    onBlur={handleBlur}                                        
                                    value={this.state.entity?.id ? this.state.entity?.id : '' }
                                    disabled                                  
                                />
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.id ? <span className="info">{errors.id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Name</span>
                                
                                
                                
                                <Input
                                    type="text"
                                    name="name"
                                    onChange={(e) => {
                                        this.state.entity.name = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.name ? this.state.entity?.name : '' }
                                    
                                />
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.name ? <span className="info">{errors.name}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Slug*</span>
                                
                                
                                
                                <Input
                                    type="text"
                                    name="slug"
                                    onChange={(e) => {
                                        this.state.entity.slug = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.slug ? this.state.entity?.slug : '' }
                                    
                                />
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.slug ? <span className="info">{errors.slug}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Number*</span>
                                
                                
                                
                                
                                <Input
                                    type="number"
                                    name="number"
                                    onChange={(e) => {
                                        this.state.entity.number = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.number ? this.state.entity?.number : '' }
                                />
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.number ? <span className="info">{errors.number}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Main Image</span>
                                
                                
                                
                                <div>
                                <Input
                                    disabled
                                    type="text"
                                    name="main_image"
                                    onChange={(e) => {
                                        this.state.entity.main_image = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.main_image ? this.state.entity?.main_image : '' }                                    
                                />
                                <ImageUploading                                    
                                    value={ this.state.entity.main_image }
                                    onChange={(imageList, addUpdateIndex) => this.onImageChange(imageList, addUpdateIndex, "main_image")}
                                    onError={ (err) => {console.log(err)} }
                                    maxNumber={1}
                                    dataURLKey="data_url">
                                    {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <Button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        >
                                        Click or Drop here
                                        </Button>
                                        &nbsp; 
                                        { this.state.entity.main_image ?                                        
                                        <div className="image-item">
                                            <img src={this.state.entity.main_image} alt="" width="100" />
                                            <div className="image-item__btn-wrapper">                                                
                                            <Button className="btn-danger" onClick={() => {
                                                this.state.entity.main_image = undefined
                                                this.setState(this.state)
                                            }}>Remove</Button>
                                            </div>
                                        </div>
                                        : '' }
                                    </div>
                                    )}
                                </ImageUploading>
                                </div>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.main_image ? <span className="info">{errors.main_image}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Tagline</span>
                                
                                
                                
                                <Input
                                    type="text"
                                    name="tagline"
                                    onChange={(e) => {
                                        this.state.entity.tagline = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.tagline ? this.state.entity?.tagline : '' }
                                    
                                />
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.tagline ? <span className="info">{errors.tagline}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Description</span>
                                
                                
                                
                                <Input
                                    type="textarea"
                                    name="description"
                                    onChange={(e) => {
                                        this.state.entity.description = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.description ? this.state.entity?.description : '' }
                                    
                                />
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.description ? <span className="info">{errors.description}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Start Date</span>
                                
                                
                                
                                                                    
                                
                                                                    
                                <Input
                                    type="date"
                                    name="start_date"
                                    onChange={(e) => {
                                        this.state.entity.start_date = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.start_date ? this.state.entity?.start_date : '' }                                  
                                    
                                />                                    
                                
                                
                                
                                

                                
                                
            
                                { errors.start_date ? <span className="info">{errors.start_date}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>End Date</span>
                                
                                
                                
                                                                    
                                
                                                                    
                                <Input
                                    type="date"
                                    name="end_date"
                                    onChange={(e) => {
                                        this.state.entity.end_date = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.end_date ? this.state.entity?.end_date : '' }                                  
                                    
                                />                                    
                                
                                
                                
                                

                                
                                
            
                                { errors.end_date ? <span className="info">{errors.end_date}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Airing Details</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
                                <div className="jsonFieldWrapper">
                                <div className="jsonFieldItems">
                                    { this.state.entity?.airing_details !== undefined && this.state.entity?.airing_details instanceof Array ? this.state.entity?.airing_details.map((el, i) => {
                                        return (<div key={JSON.stringify(el)} className="jsonFieldItem" 
                                            onClick={() => {
                                                this.props.popups.open(GPGModal, {
                                                        title: <b>Edit Airing Details</b>,
                                                        content:<SeasonAiringDetailsForm 
                                                            entity={ el } 
                                                            onSubmit={(data) => {                                                                                                            
                                                                this.state.entity.airing_details[i] = data
                                                                this.setState(this.state)
                                                                this.props.popups.closeAll();                                                    
                                                            } 
                                                        }  />
                                                    }
                                                )
                                            } }
                                        >
                                            
                                            <div><span>day:</span> <b>{el.day}</b></div>
                                            
                                            <div><span>hour:</span> <b>{el.hour}</b></div>
                                            
                                            <div><span>channels:</span> <b>{el.channels}</b></div>
                                            
                                        </div>)
                                    }) : undefined}
                                </div>
                                <Button onClick={() => {
                                    this.props.popups.open(GPGModal, {
                                                title: <b>Add Airing Details</b>,
                                                content: <SeasonAiringDetailsForm 
                                                    entity={ {} } 
                                                    onSubmit={(data) => {                                                    
                                                        if (!this.state.entity.airing_details) {
                                                            this.state.entity.airing_details = []
                                                        }
                                                        this.state.entity.airing_details.push(data)
                                                        this.setState(this.state)
                                                        this.props.popups.closeAll();                                                    
                                                    } 
                                                }  />,
                                                onClose: (...params) => {
                                                }
                                                }); 
                                }}>Add</Button>
                                </div>
                                
                                
                                
            
                                { errors.airing_details ? <span className="info">{errors.airing_details}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Status*</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="status"
                                    onChange={(e) => {
                                        this.state.entity.status = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.status ? this.state.entity?.status : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="enabled">Enabled</option>
                                    
                                    <option value="disabled">Disabled</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.status ? <span className="info">{errors.status}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Created*</span>
                                
                                
                                
                                                                    
                                
                                
                                                                    
                                <Input
                                    type="text"
                                    name="created_at"
                                    onChange={(e) => {
                                        this.state.entity.created_at = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.created_at ? this.state.entity?.created_at : '' }
                                    disabled
                                />                                    
                                
                                
                                

                                
                                
            
                                { errors.created_at ? <span className="info">{errors.created_at}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Updated At*</span>
                                
                                
                                
                                                                    
                                
                                
                                                                    
                                <Input
                                    type="text"
                                    name="updated_at"
                                    onChange={(e) => {
                                        this.state.entity.updated_at = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.updated_at ? this.state.entity?.updated_at : '' }
                                    disabled
                                />                                    
                                
                                
                                

                                
                                
            
                                { errors.updated_at ? <span className="info">{errors.updated_at}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Drag Show*</span>
                                
                                
                                    
                                        <SearchEntity 
                                            entityIdentifier="drag_show" 
                                            entityName="DragShow"
                                            onChange={(e) => {
                                                this.state.entity.drag_show_id = e.value
                                                this.setState(this.state)                                                
                                            } }
                                            value={ {value:this.state.entity?.drag_show_id, label:this.state.entity?.drag_show_id ? "Loading..." : "Search"} }
                                             />
                                    
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.drag_show_id ? <span className="info">{errors.drag_show_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Winner</span>
                                
                                
                                    
                                        <SearchEntity 
                                            entityIdentifier="drag" 
                                            entityName="Drag"
                                            onChange={(e) => {
                                                this.state.entity.winner_id = e.value
                                                this.setState(this.state)                                                
                                            } }
                                            value={ {value:this.state.entity?.winner_id, label:this.state.entity?.winner_id ? "Loading..." : "Search"} }
                                             />
                                    
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.winner_id ? <span className="info">{errors.winner_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Second Winner</span>
                                
                                
                                    
                                        <SearchEntity 
                                            entityIdentifier="drag" 
                                            entityName="Drag"
                                            onChange={(e) => {
                                                this.state.entity.second_winner_id = e.value
                                                this.setState(this.state)                                                
                                            } }
                                            value={ {value:this.state.entity?.second_winner_id, label:this.state.entity?.second_winner_id ? "Loading..." : "Search"} }
                                             />
                                    
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.second_winner_id ? <span className="info">{errors.second_winner_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Miss Congeniality</span>
                                
                                
                                    
                                        <SearchEntity 
                                            entityIdentifier="drag" 
                                            entityName="Drag"
                                            onChange={(e) => {
                                                this.state.entity.congeniality_winner_id = e.value
                                                this.setState(this.state)                                                
                                            } }
                                            value={ {value:this.state.entity?.congeniality_winner_id, label:this.state.entity?.congeniality_winner_id ? "Loading..." : "Search"} }
                                             />
                                    
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.congeniality_winner_id ? <span className="info">{errors.congeniality_winner_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>User*</span>
                                
                                                                
                                ({this.state.user?.name}) &nbsp;
                                {this.state.entity.user_id = this.state.user?.id }
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.user_id ? <span className="info">{errors.user_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Spotify Playlist ID</span>
                                
                                
                                
                                <Input
                                    type="text"
                                    name="playlist"
                                    onChange={(e) => {
                                        this.state.entity.playlist = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.playlist ? this.state.entity?.playlist : '' }
                                    
                                />
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.playlist ? <span className="info">{errors.playlist}</span> : undefined }
                                </FormGroup>
                                                                
                                
                                
                                <Button type="submit">
                                    Submit
                                </Button>
                            </form>
                        )}}
                        </Formik>                    
                </div>
        )
    }
}

export default SeasonForm