import React from 'react'
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Client from '../client'
import {gql} from '@apollo/client';
import EpisodeForm from '../forms/EpisodeForm';
import { usePopupManager } from "react-popup-manager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";
import { MdCreate } from "react-icons/md";


class EpisodeEdit extends React.Component {
    constructor(props) {
        super(props)        
        this.state = {
            pending:false,
            entity:{
                id: this.props.params.id,
            },
            notFound:false,
            images:[]
        }                             
    }

    componentDidMount() {        
        if (this.props.params.id !== undefined) {
            this.state.pending = true
            this.setState(this.state)
            this.fetchEntity();
        }
    }

    fetchEntity() {
        Client
            .query({
                query: gql`
                query EpisodeByID($id: ID!) {
                    EpisodeByID(id:$id){ 
                        id,
                        name,
                        number,
                        slug,
                        description,
                        main_image,
                        air_date,
                        guest_judge,
                        episode_type,
                        mini_challenge_name,
                        maxi_challenge_name,
                        challenge_media{
                            name,description,video_url,image_url,
                        },
                        runway_theme,
                        lipsync{
                            song_name,song_artist,song_url,drag_id,drag2_id,drag3_id,drag4_id,drag5_id,drag6_id,order,video_url,no_looser,no_single_winner,
                        },
                        status,
                        created_at,
                        season_id,
                        mini_winner_id,
                        second_mini_winner_id,
                        third_mini_winner_id,
                        fourth_mini_winner_id,
                        fifth_mini_winner_id,
                        sixth_mini_winner_id,
                        winner_id,
                        second_winner_id,
                        third_winner_id,
                        fourth_winner_id,
                        fifth_winner_id,
                        sixth_winner_id,
                        eliminated_id,
                        second_eliminated_id,
                        elimination_msg,
                        user_id,
                    }
                }
                `,
                variables:{
                    id:this.state.entity.id,                    
                }
            })
            .then((result) => {
                this.state.pending = false
                var data = result.data.EpisodeByID
                if (data.length === 0) {
                    this.state.notFound = true
                    toast.error('Entity not found after insert',{
                        position: toast.POSITION.TOP_CENTER
                    });
                } else {
                    console.log(data[0])
                    this.state.entity = JSON.parse(JSON.stringify(data[0]))
                }                              
                this.setState(this.state)
            })
            .catch((err) => {
                toast.error('Error fetching entity',{
                        position: toast.POSITION.TOP_CENTER
                });
                console.error(err);
            });
    }

    upsertEntity() {
        Client
            .mutate({
                mutation: gql`
                mutation upsertEpisode($input: EpisodeInput!) {
                    upsertEpisode(input:$input){ 
                        id
                    }
                }
                `,
                variables:{
                    input:{
                        id:(this.state.entity.id ? this.state.entity.id : ''),
                        name:this.state.entity.name ? this.state.entity.name : '',
                        number:this.state.entity.number ? this.state.entity.number : 0,
                        slug:this.state.entity.slug ? this.state.entity.slug : '',
                        description:this.state.entity.description ? this.state.entity.description : '',
                        main_image:this.state.entity.main_image ? this.state.entity.main_image : '',
                        air_date:this.state.entity.air_date ? this.state.entity.air_date : '1999-09-09',
                        guest_judge:this.state.entity.guest_judge ? this.state.entity.guest_judge : '',
                        episode_type:this.state.entity.episode_type ? this.state.entity.episode_type : '',
                        mini_challenge_name:this.state.entity.mini_challenge_name ? this.state.entity.mini_challenge_name : '',
                        maxi_challenge_name:this.state.entity.maxi_challenge_name ? this.state.entity.maxi_challenge_name : '',
                        challenge_media:this.state.entity.challenge_media ? this.state.entity.challenge_media : [],
                        runway_theme:this.state.entity.runway_theme ? this.state.entity.runway_theme : '',
                        lipsync:this.state.entity.lipsync ? this.state.entity.lipsync : [],
                        status:this.state.entity.status ? this.state.entity.status : '',
                        created_at:this.state.entity.created_at ? this.state.entity.created_at : '1999-09-09 09:09:09',
                        season_id:this.state.entity.season_id ? this.state.entity.season_id : '',
                        mini_winner_id:this.state.entity.mini_winner_id ? this.state.entity.mini_winner_id : '',
                        second_mini_winner_id:this.state.entity.second_mini_winner_id ? this.state.entity.second_mini_winner_id : '',
                        third_mini_winner_id:this.state.entity.third_mini_winner_id ? this.state.entity.third_mini_winner_id : '',
                        fourth_mini_winner_id:this.state.entity.fourth_mini_winner_id ? this.state.entity.fourth_mini_winner_id : '',
                        fifth_mini_winner_id:this.state.entity.fifth_mini_winner_id ? this.state.entity.fifth_mini_winner_id : '',
                        sixth_mini_winner_id:this.state.entity.sixth_mini_winner_id ? this.state.entity.sixth_mini_winner_id : '',
                        winner_id:this.state.entity.winner_id ? this.state.entity.winner_id : '',
                        second_winner_id:this.state.entity.second_winner_id ? this.state.entity.second_winner_id : '',
                        third_winner_id:this.state.entity.third_winner_id ? this.state.entity.third_winner_id : '',
                        fourth_winner_id:this.state.entity.fourth_winner_id ? this.state.entity.fourth_winner_id : '',
                        fifth_winner_id:this.state.entity.fifth_winner_id ? this.state.entity.fifth_winner_id : '',
                        sixth_winner_id:this.state.entity.sixth_winner_id ? this.state.entity.sixth_winner_id : '',
                        eliminated_id:this.state.entity.eliminated_id ? this.state.entity.eliminated_id : '',
                        second_eliminated_id:this.state.entity.second_eliminated_id ? this.state.entity.second_eliminated_id : '',
                        elimination_msg:this.state.entity.elimination_msg ? this.state.entity.elimination_msg : '',
                        user_id:this.state.entity.user_id ? this.state.entity.user_id : '',
                    }                    
                }
            })
            .then((result) => {
                var data = result.data.upsertEpisode
                if (!data.id) {
                    this.state.notFound = true
                } else {                    
                    this.state.entity.id = data.id
                    window.history.replaceState(null, document.title, "/episode/edit/"+this.state.entity.id)
                }                 
                window.scrollTo(0, 0)
                toast.success(this.props.params.id == undefined ? 'Created Successfully' : 'Edited Successfully',{
                    position: toast.POSITION.TOP_CENTER
                });
                setTimeout(() => {
                    toast.dismiss()
                    window.location.reload(false);
                }, "500")
                
            })
            .catch((err) => {
                toast.error('Error upserting entity',{
                        position: toast.POSITION.TOP_CENTER
                });
                console.error(err);
            });
    }    

    render() {              
        return (            
           <div>
                <Header />  
                <div className="actionsBar">  
                    <h2 className="pageTitle">{this.props.params.id === undefined ? 'Create' : 'Edit'} Episode</h2>                                  
                    { this.props.params.id !== undefined ? 
                    <a onClick={() => {
                        window.history.replaceState(null, document.title, "/episode/edit/")
                        window.location.reload(false);
                    }} ><MdCreate /> Create New</a>
                    : undefined }
                </div>
                {this.state.pending ? <div className="formWrapper" style={ {textAlign:"center"} }>Loading...</div> : 
                    <EpisodeForm {...this.props} entity={this.state.entity} onSubmit={(data) =>{
                        this.upsertEntity()                        
                    } } />
                }
                <ToastContainer />
            </div> 
        )
    }
}

export default (props) => <EpisodeEdit {...props}
            params={useParams()} popups={usePopupManager()} />