import React from 'react'
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Client from '../client'
import {gql} from '@apollo/client';
import EpisodeHasDragForm from '../forms/EpisodeHasDragForm';
import { usePopupManager } from "react-popup-manager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";
import { MdCreate } from "react-icons/md";


class EpisodeHasDragEdit extends React.Component {
    constructor(props) {
        super(props)        
        this.state = {
            pending:false,
            entity:{
                id: this.props.params.id,
            },
            notFound:false,
            images:[]
        }                             
    }

    componentDidMount() {        
        if (this.props.params.id !== undefined) {
            this.state.pending = true
            this.setState(this.state)
            this.fetchEntity();
        }
    }

    fetchEntity() {
        Client
            .query({
                query: gql`
                query EpisodeHasDragByID($id: ID!) {
                    EpisodeHasDragByID(id:$id){ 
                        id,
                        drag_id,
                        episode_id,
                        position,
                        status,
                        created_at,
                        runway_look,
                        details,
                        other_look,
                        details_other,
                        third_look,
                        details_third,
                        fourth_look,
                        details_fourth,
                        fifth_look,
                        details_fifth,
                    }
                }
                `,
                variables:{
                    id:this.state.entity.id,                    
                }
            })
            .then((result) => {
                this.state.pending = false
                var data = result.data.EpisodeHasDragByID
                if (data.length === 0) {
                    this.state.notFound = true
                    toast.error('Entity not found after insert',{
                        position: toast.POSITION.TOP_CENTER
                    });
                } else {
                    console.log(data[0])
                    this.state.entity = JSON.parse(JSON.stringify(data[0]))
                }                              
                this.setState(this.state)
            })
            .catch((err) => {
                toast.error('Error fetching entity',{
                        position: toast.POSITION.TOP_CENTER
                });
                console.error(err);
            });
    }

    upsertEntity() {
        Client
            .mutate({
                mutation: gql`
                mutation upsertEpisodeHasDrag($input: EpisodeHasDragInput!) {
                    upsertEpisodeHasDrag(input:$input){ 
                        id
                    }
                }
                `,
                variables:{
                    input:{
                        id:(this.state.entity.id ? this.state.entity.id : ''),
                        drag_id:this.state.entity.drag_id ? this.state.entity.drag_id : '',
                        episode_id:this.state.entity.episode_id ? this.state.entity.episode_id : '',
                        position:this.state.entity.position ? this.state.entity.position : '',
                        status:this.state.entity.status ? this.state.entity.status : '',
                        created_at:this.state.entity.created_at ? this.state.entity.created_at : '1999-09-09 09:09:09',
                        runway_look:this.state.entity.runway_look ? this.state.entity.runway_look : '',
                        details:this.state.entity.details ? this.state.entity.details : '',
                        other_look:this.state.entity.other_look ? this.state.entity.other_look : '',
                        details_other:this.state.entity.details_other ? this.state.entity.details_other : '',
                        third_look:this.state.entity.third_look ? this.state.entity.third_look : '',
                        details_third:this.state.entity.details_third ? this.state.entity.details_third : '',
                        fourth_look:this.state.entity.fourth_look ? this.state.entity.fourth_look : '',
                        details_fourth:this.state.entity.details_fourth ? this.state.entity.details_fourth : '',
                        fifth_look:this.state.entity.fifth_look ? this.state.entity.fifth_look : '',
                        details_fifth:this.state.entity.details_fifth ? this.state.entity.details_fifth : '',
                    }                    
                }
            })
            .then((result) => {
                var data = result.data.upsertEpisodeHasDrag
                if (!data.id) {
                    this.state.notFound = true
                } else {                    
                    this.state.entity.id = data.id
                    window.history.replaceState(null, document.title, "/episode_has_drag/edit/"+this.state.entity.id)
                }                 
                window.scrollTo(0, 0)
                toast.success(this.props.params.id == undefined ? 'Created Successfully' : 'Edited Successfully',{
                    position: toast.POSITION.TOP_CENTER
                });
                setTimeout(() => {
                    toast.dismiss()
                    window.location.reload(false);
                }, "500")
                
            })
            .catch((err) => {
                toast.error('Error upserting entity',{
                        position: toast.POSITION.TOP_CENTER
                });
                console.error(err);
            });
    }    

    render() {              
        return (            
           <div>
                <Header />  
                <div className="actionsBar">  
                    <h2 className="pageTitle">{this.props.params.id === undefined ? 'Create' : 'Edit'} Episode Has Drag</h2>                                  
                    { this.props.params.id !== undefined ? 
                    <a onClick={() => {
                        window.history.replaceState(null, document.title, "/episode_has_drag/edit/")
                        window.location.reload(false);
                    }} ><MdCreate /> Create New</a>
                    : undefined }
                </div>
                {this.state.pending ? <div className="formWrapper" style={ {textAlign:"center"} }>Loading...</div> : 
                    <EpisodeHasDragForm {...this.props} entity={this.state.entity} onSubmit={(data) =>{
                        this.upsertEntity()                        
                    } } />
                }
                <ToastContainer />
            </div> 
        )
    }
}

export default (props) => <EpisodeHasDragEdit {...props}
            params={useParams()} popups={usePopupManager()} />