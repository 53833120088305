import React from 'react'
import { Formik } from 'formik';
import { Card, CardBody, CardTitle, CardText, Form, Label, Input, Button, FormGroup } from 'reactstrap'
import ImageUploading from 'react-images-uploading';
import { GPGModal } from '../components/GPGModal';
import { toast } from 'react-toastify';
import axios from 'axios';
import {StringToColour} from "../Utils";
import FetchUser from '../FetchUser';
import {SearchEntity} from '../components/SearchEntity'



    

    

    

    

    

    

    

    

    

    

    

    

    

    

    

    


class EpisodeHasDragForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity: props.entity
        }           

        
                            
                                
            
        
                            
                                
            
        
                            
                                
            
        
            
        
                            
                                
            
        
                            
                                
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
    }

    componentDidMount() {
        FetchUser().then(res => {
            if (res.status === 200) {
                this.state.user = res.data
                this.setState(this.state)
            }
        })
    }

    onImageChange(imageList, addUpdateIndex, fieldIdentifier) {
        // data for submit
        console.log(imageList)
        if (imageList.length > 0) {
            var imageFile = imageList[0]
            var formData = new FormData();            
            formData.append("file", imageFile.file);
            axios.post('https://api.dragium.com/upload', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(response => {                    
                    if (response && response.data && response.data.file_path) {
                        this.state.entity[fieldIdentifier] = response.data.file_path
                        this.setState(this.state)
                    }
                }
            )
        }
    };

    render() {
        return (
            <div className="formWrapper"  style={{borderTopColor:StringToColour('episode_has_drag')}}> 
                    <Formik    
                        initialValues={ this.state.entity }                    
                        validate={values => {
                            const errors = {};   
                            
                            if (!this.state.entity.drag_id) {
                            
                                errors.drag_id = "Required";
                            
                            }
                            if (!this.state.entity.episode_id) {
                            
                                errors.episode_id = "Required";
                            
                            }
                            if (!this.state.entity.status) {
                            
                                errors.status = "Required";
                            
                            }
                            if (!this.state.entity.created_at) {
                            
                            }                                               
                            return errors;
                        }}  
                        onSubmit={(values, actions) =>{                            
                            this.props.onSubmit(this.state.entity)                                
                        }}                      
                        >
                        {({                            
                            errors,   
                            handleBlur,
                            handleSubmit,
                            isValid,                                                                                 
                        }) => {
                        if (!isValid) {                                
                            toast.error('Error validating form',{
                                position: toast.POSITION.TOP_CENTER,
                                toastId: "episode_has_drag-validation"
                            });                                
                        } else {
                            toast.dismiss("episode_has_drag-validation");
                        }
                        return (                            
                            <form onSubmit={handleSubmit}>
                                
                                                                
                                <FormGroup className="inputWrapper">
                                <span>ID*</span>
                                
                                
                                <Input
                                    type="text"
                                    name="id"
                                    onChange={(e) => {
                                        this.state.entity.id = e.target.value
                                        this.setState(this.state)
                                    } }
                                    onBlur={handleBlur}                                        
                                    value={this.state.entity?.id ? this.state.entity?.id : '' }
                                    disabled                                  
                                />
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.id ? <span className="info">{errors.id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Drag*</span>
                                
                                
                                    
                                        <SearchEntity 
                                            entityIdentifier="drag" 
                                            entityName="Drag"
                                            onChange={(e) => {
                                                this.state.entity.drag_id = e.value
                                                this.setState(this.state)                                                
                                            } }
                                            value={ {value:this.state.entity?.drag_id, label:this.state.entity?.drag_id ? "Loading..." : "Search"} }
                                             />
                                    
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.drag_id ? <span className="info">{errors.drag_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Episode*</span>
                                
                                
                                    
                                        <SearchEntity 
                                            entityIdentifier="episode" 
                                            entityName="Episode"
                                            onChange={(e) => {
                                                this.state.entity.episode_id = e.value
                                                this.setState(this.state)                                                
                                            } }
                                            value={ {value:this.state.entity?.episode_id, label:this.state.entity?.episode_id ? "Loading..." : "Search"} }
                                             />
                                    
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.episode_id ? <span className="info">{errors.episode_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>position</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="position"
                                    onChange={(e) => {
                                        this.state.entity.position = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.position ? this.state.entity?.position : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="safe">Safe</option>
                                    
                                    <option value="high">High</option>
                                    
                                    <option value="low">Low</option>
                                    
                                    <option value="win">Win</option>
                                    
                                    <option value="bottom">Bottom</option>
                                    
                                    <option value="eliminated">Eliminated</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.position ? <span className="info">{errors.position}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Status*</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="status"
                                    onChange={(e) => {
                                        this.state.entity.status = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.status ? this.state.entity?.status : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="enabled">Enabled</option>
                                    
                                    <option value="disabled">Disabled</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.status ? <span className="info">{errors.status}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Created*</span>
                                
                                
                                
                                                                    
                                
                                
                                                                    
                                <Input
                                    type="text"
                                    name="created_at"
                                    onChange={(e) => {
                                        this.state.entity.created_at = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.created_at ? this.state.entity?.created_at : '' }
                                    disabled
                                />                                    
                                
                                
                                

                                
                                
            
                                { errors.created_at ? <span className="info">{errors.created_at}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Runway Look</span>
                                
                                
                                
                                <div>
                                <Input
                                    disabled
                                    type="text"
                                    name="runway_look"
                                    onChange={(e) => {
                                        this.state.entity.runway_look = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.runway_look ? this.state.entity?.runway_look : '' }                                    
                                />
                                <ImageUploading                                    
                                    value={ this.state.entity.runway_look }
                                    onChange={(imageList, addUpdateIndex) => this.onImageChange(imageList, addUpdateIndex, "runway_look")}
                                    onError={ (err) => {console.log(err)} }
                                    maxNumber={1}
                                    dataURLKey="data_url">
                                    {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <Button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        >
                                        Click or Drop here
                                        </Button>
                                        &nbsp; 
                                        { this.state.entity.runway_look ?                                        
                                        <div className="image-item">
                                            <img src={this.state.entity.runway_look} alt="" width="100" />
                                            <div className="image-item__btn-wrapper">                                                
                                            <Button className="btn-danger" onClick={() => {
                                                this.state.entity.runway_look = undefined
                                                this.setState(this.state)
                                            }}>Remove</Button>
                                            </div>
                                        </div>
                                        : '' }
                                    </div>
                                    )}
                                </ImageUploading>
                                </div>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.runway_look ? <span className="info">{errors.runway_look}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Details</span>
                                
                                
                                
                                <Input
                                    type="textarea"
                                    name="details"
                                    onChange={(e) => {
                                        this.state.entity.details = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.details ? this.state.entity?.details : '' }
                                    
                                />
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.details ? <span className="info">{errors.details}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Other Look</span>
                                
                                
                                
                                <div>
                                <Input
                                    disabled
                                    type="text"
                                    name="other_look"
                                    onChange={(e) => {
                                        this.state.entity.other_look = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.other_look ? this.state.entity?.other_look : '' }                                    
                                />
                                <ImageUploading                                    
                                    value={ this.state.entity.other_look }
                                    onChange={(imageList, addUpdateIndex) => this.onImageChange(imageList, addUpdateIndex, "other_look")}
                                    onError={ (err) => {console.log(err)} }
                                    maxNumber={1}
                                    dataURLKey="data_url">
                                    {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <Button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        >
                                        Click or Drop here
                                        </Button>
                                        &nbsp; 
                                        { this.state.entity.other_look ?                                        
                                        <div className="image-item">
                                            <img src={this.state.entity.other_look} alt="" width="100" />
                                            <div className="image-item__btn-wrapper">                                                
                                            <Button className="btn-danger" onClick={() => {
                                                this.state.entity.other_look = undefined
                                                this.setState(this.state)
                                            }}>Remove</Button>
                                            </div>
                                        </div>
                                        : '' }
                                    </div>
                                    )}
                                </ImageUploading>
                                </div>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.other_look ? <span className="info">{errors.other_look}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Other Details</span>
                                
                                
                                
                                <Input
                                    type="textarea"
                                    name="details_other"
                                    onChange={(e) => {
                                        this.state.entity.details_other = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.details_other ? this.state.entity?.details_other : '' }
                                    
                                />
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.details_other ? <span className="info">{errors.details_other}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Third Look</span>
                                
                                
                                
                                <div>
                                <Input
                                    disabled
                                    type="text"
                                    name="third_look"
                                    onChange={(e) => {
                                        this.state.entity.third_look = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.third_look ? this.state.entity?.third_look : '' }                                    
                                />
                                <ImageUploading                                    
                                    value={ this.state.entity.third_look }
                                    onChange={(imageList, addUpdateIndex) => this.onImageChange(imageList, addUpdateIndex, "third_look")}
                                    onError={ (err) => {console.log(err)} }
                                    maxNumber={1}
                                    dataURLKey="data_url">
                                    {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <Button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        >
                                        Click or Drop here
                                        </Button>
                                        &nbsp; 
                                        { this.state.entity.third_look ?                                        
                                        <div className="image-item">
                                            <img src={this.state.entity.third_look} alt="" width="100" />
                                            <div className="image-item__btn-wrapper">                                                
                                            <Button className="btn-danger" onClick={() => {
                                                this.state.entity.third_look = undefined
                                                this.setState(this.state)
                                            }}>Remove</Button>
                                            </div>
                                        </div>
                                        : '' }
                                    </div>
                                    )}
                                </ImageUploading>
                                </div>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.third_look ? <span className="info">{errors.third_look}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Third Details</span>
                                
                                
                                
                                <Input
                                    type="textarea"
                                    name="details_third"
                                    onChange={(e) => {
                                        this.state.entity.details_third = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.details_third ? this.state.entity?.details_third : '' }
                                    
                                />
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.details_third ? <span className="info">{errors.details_third}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Fourth Look</span>
                                
                                
                                
                                <div>
                                <Input
                                    disabled
                                    type="text"
                                    name="fourth_look"
                                    onChange={(e) => {
                                        this.state.entity.fourth_look = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.fourth_look ? this.state.entity?.fourth_look : '' }                                    
                                />
                                <ImageUploading                                    
                                    value={ this.state.entity.fourth_look }
                                    onChange={(imageList, addUpdateIndex) => this.onImageChange(imageList, addUpdateIndex, "fourth_look")}
                                    onError={ (err) => {console.log(err)} }
                                    maxNumber={1}
                                    dataURLKey="data_url">
                                    {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <Button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        >
                                        Click or Drop here
                                        </Button>
                                        &nbsp; 
                                        { this.state.entity.fourth_look ?                                        
                                        <div className="image-item">
                                            <img src={this.state.entity.fourth_look} alt="" width="100" />
                                            <div className="image-item__btn-wrapper">                                                
                                            <Button className="btn-danger" onClick={() => {
                                                this.state.entity.fourth_look = undefined
                                                this.setState(this.state)
                                            }}>Remove</Button>
                                            </div>
                                        </div>
                                        : '' }
                                    </div>
                                    )}
                                </ImageUploading>
                                </div>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.fourth_look ? <span className="info">{errors.fourth_look}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Fourth Details</span>
                                
                                
                                
                                <Input
                                    type="textarea"
                                    name="details_fourth"
                                    onChange={(e) => {
                                        this.state.entity.details_fourth = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.details_fourth ? this.state.entity?.details_fourth : '' }
                                    
                                />
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.details_fourth ? <span className="info">{errors.details_fourth}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Fifth Look</span>
                                
                                
                                
                                <div>
                                <Input
                                    disabled
                                    type="text"
                                    name="fifth_look"
                                    onChange={(e) => {
                                        this.state.entity.fifth_look = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.fifth_look ? this.state.entity?.fifth_look : '' }                                    
                                />
                                <ImageUploading                                    
                                    value={ this.state.entity.fifth_look }
                                    onChange={(imageList, addUpdateIndex) => this.onImageChange(imageList, addUpdateIndex, "fifth_look")}
                                    onError={ (err) => {console.log(err)} }
                                    maxNumber={1}
                                    dataURLKey="data_url">
                                    {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <Button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        >
                                        Click or Drop here
                                        </Button>
                                        &nbsp; 
                                        { this.state.entity.fifth_look ?                                        
                                        <div className="image-item">
                                            <img src={this.state.entity.fifth_look} alt="" width="100" />
                                            <div className="image-item__btn-wrapper">                                                
                                            <Button className="btn-danger" onClick={() => {
                                                this.state.entity.fifth_look = undefined
                                                this.setState(this.state)
                                            }}>Remove</Button>
                                            </div>
                                        </div>
                                        : '' }
                                    </div>
                                    )}
                                </ImageUploading>
                                </div>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.fifth_look ? <span className="info">{errors.fifth_look}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Details Fifth</span>
                                
                                
                                
                                <Input
                                    type="textarea"
                                    name="details_fifth"
                                    onChange={(e) => {
                                        this.state.entity.details_fifth = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.details_fifth ? this.state.entity?.details_fifth : '' }
                                    
                                />
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.details_fifth ? <span className="info">{errors.details_fifth}</span> : undefined }
                                </FormGroup>
                                                                
                                
                                
                                <Button type="submit">
                                    Submit
                                </Button>
                            </form>
                        )}}
                        </Formik>                    
                </div>
        )
    }
}

export default EpisodeHasDragForm