import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Login from './pages/Login';
import {
  BrowserRouter,
  Routes,
  Route,  
} from "react-router-dom";
import { PopupProvider } from "react-popup-manager";


import User from "./routes/User";
import UserEdit from "./routes/UserEdit";

import Drag from "./routes/Drag";
import DragEdit from "./routes/DragEdit";

import Location from "./routes/Location";
import LocationEdit from "./routes/LocationEdit";

import UserHasDrag from "./routes/UserHasDrag";
import UserHasDragEdit from "./routes/UserHasDragEdit";

import DragShow from "./routes/DragShow";
import DragShowEdit from "./routes/DragShowEdit";

import Season from "./routes/Season";
import SeasonEdit from "./routes/SeasonEdit";

import SeasonHasDrag from "./routes/SeasonHasDrag";
import SeasonHasDragEdit from "./routes/SeasonHasDragEdit";

import Episode from "./routes/Episode";
import EpisodeEdit from "./routes/EpisodeEdit";

import EpisodeHasDrag from "./routes/EpisodeHasDrag";
import EpisodeHasDragEdit from "./routes/EpisodeHasDragEdit";

import FantasySeason from "./routes/FantasySeason";
import FantasySeasonEdit from "./routes/FantasySeasonEdit";

import FanstasySeasonHasDrag from "./routes/FanstasySeasonHasDrag";
import FanstasySeasonHasDragEdit from "./routes/FanstasySeasonHasDragEdit";


const root = ReactDOM.createRoot(document.getElementById('root')); 
root.render(   
    <PopupProvider>    
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/login" element={<Login />} />
            
            <Route path="user" element={<User />} />
            <Route path="user/edit" element={<UserEdit />} />
            <Route path="user/edit/:id" element={<UserEdit />} />
            
            <Route path="drag" element={<Drag />} />
            <Route path="drag/edit" element={<DragEdit />} />
            <Route path="drag/edit/:id" element={<DragEdit />} />
            
            <Route path="location" element={<Location />} />
            <Route path="location/edit" element={<LocationEdit />} />
            <Route path="location/edit/:id" element={<LocationEdit />} />
            
            <Route path="user_has_drag" element={<UserHasDrag />} />
            <Route path="user_has_drag/edit" element={<UserHasDragEdit />} />
            <Route path="user_has_drag/edit/:id" element={<UserHasDragEdit />} />
            
            <Route path="drag_show" element={<DragShow />} />
            <Route path="drag_show/edit" element={<DragShowEdit />} />
            <Route path="drag_show/edit/:id" element={<DragShowEdit />} />
            
            <Route path="season" element={<Season />} />
            <Route path="season/edit" element={<SeasonEdit />} />
            <Route path="season/edit/:id" element={<SeasonEdit />} />
            
            <Route path="season_has_drag" element={<SeasonHasDrag />} />
            <Route path="season_has_drag/edit" element={<SeasonHasDragEdit />} />
            <Route path="season_has_drag/edit/:id" element={<SeasonHasDragEdit />} />
            
            <Route path="episode" element={<Episode />} />
            <Route path="episode/edit" element={<EpisodeEdit />} />
            <Route path="episode/edit/:id" element={<EpisodeEdit />} />
            
            <Route path="episode_has_drag" element={<EpisodeHasDrag />} />
            <Route path="episode_has_drag/edit" element={<EpisodeHasDragEdit />} />
            <Route path="episode_has_drag/edit/:id" element={<EpisodeHasDragEdit />} />
            
            <Route path="fantasy_season" element={<FantasySeason />} />
            <Route path="fantasy_season/edit" element={<FantasySeasonEdit />} />
            <Route path="fantasy_season/edit/:id" element={<FantasySeasonEdit />} />
            
            <Route path="fanstasy_season_has_drag" element={<FanstasySeasonHasDrag />} />
            <Route path="fanstasy_season_has_drag/edit" element={<FanstasySeasonHasDragEdit />} />
            <Route path="fanstasy_season_has_drag/edit/:id" element={<FanstasySeasonHasDragEdit />} />
                    
        </Routes>
    </BrowserRouter>
    </PopupProvider>
);
