import React from 'react'
import { Formik } from 'formik';
import { Card, CardBody, CardTitle, CardText, Form, Label, Input, Button, FormGroup } from 'reactstrap'
import ImageUploading from 'react-images-uploading';
import { GPGModal } from '../components/GPGModal';
import { toast } from 'react-toastify';
import axios from 'axios';
import {StringToColour} from "../Utils";
import FetchUser from '../FetchUser';
import {SearchEntity} from '../components/SearchEntity'



    

    

    

    

    

    

    

    

    

    

    
import DragEntranceQuoteForm from '../forms/DragEntranceQuoteForm';
    

    
import DragFarewellQuoteForm from '../forms/DragFarewellQuoteForm';
    

    
import DragNamesForm from '../forms/DragNamesForm';
    

    
import DragLinksForm from '../forms/DragLinksForm';
    

    
import DragMediaForm from '../forms/DragMediaForm';
    

    
import DragQuotesForm from '../forms/DragQuotesForm';
    

    

    

    

    

    

    

    

    

    

    

    

    

    

    

    

    

    

    

    

    


class DragForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entity: props.entity
        }           

        
                            
                                
            
        
                            
                                
            
        
                            
                                
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
                            
                                
            
        
            
        
            
        
            
        
            
        
            
        
                            
                                
            
        
                            
                                
            
        
                            
                                
            
        
                            
                                
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
            
        
    }

    componentDidMount() {
        FetchUser().then(res => {
            if (res.status === 200) {
                this.state.user = res.data
                this.setState(this.state)
            }
        })
    }

    onImageChange(imageList, addUpdateIndex, fieldIdentifier) {
        // data for submit
        console.log(imageList)
        if (imageList.length > 0) {
            var imageFile = imageList[0]
            var formData = new FormData();            
            formData.append("file", imageFile.file);
            axios.post('https://api.dragium.com/upload', formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            }).then(response => {                    
                    if (response && response.data && response.data.file_path) {
                        this.state.entity[fieldIdentifier] = response.data.file_path
                        this.setState(this.state)
                    }
                }
            )
        }
    };

    render() {
        return (
            <div className="formWrapper"  style={{borderTopColor:StringToColour('drag')}}> 
                    <Formik    
                        initialValues={ this.state.entity }                    
                        validate={values => {
                            const errors = {};   
                            
                            if (!this.state.entity.name) {
                            
                                errors.name = "Required";
                            
                            }
                            if (!this.state.entity.slug) {
                            
                                errors.slug = "Required";
                            
                            }
                            if (!this.state.entity.birthdate) {
                            
                                errors.birthdate = "Required";
                            
                            }
                            if (!this.state.entity.status) {
                            
                                errors.status = "Required";
                            
                            }
                            if (!this.state.entity.created_at) {
                            
                            }
                            if (!this.state.entity.updated_at) {
                            
                            }
                            if (!this.state.entity.user_id) {
                            
                                errors.user_id = "Required";
                            
                            }                                               
                            return errors;
                        }}  
                        onSubmit={(values, actions) =>{                            
                            this.props.onSubmit(this.state.entity)                                
                        }}                      
                        >
                        {({                            
                            errors,   
                            handleBlur,
                            handleSubmit,
                            isValid,                                                                                 
                        }) => {
                        if (!isValid) {                                
                            toast.error('Error validating form',{
                                position: toast.POSITION.TOP_CENTER,
                                toastId: "drag-validation"
                            });                                
                        } else {
                            toast.dismiss("drag-validation");
                        }
                        return (                            
                            <form onSubmit={handleSubmit}>
                                
                                                                
                                <FormGroup className="inputWrapper">
                                <span>ID*</span>
                                
                                
                                <Input
                                    type="text"
                                    name="id"
                                    onChange={(e) => {
                                        this.state.entity.id = e.target.value
                                        this.setState(this.state)
                                    } }
                                    onBlur={handleBlur}                                        
                                    value={this.state.entity?.id ? this.state.entity?.id : '' }
                                    disabled                                  
                                />
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.id ? <span className="info">{errors.id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Name*</span>
                                
                                
                                
                                <Input
                                    type="text"
                                    name="name"
                                    onChange={(e) => {
                                        this.state.entity.name = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.name ? this.state.entity?.name : '' }
                                    
                                />
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.name ? <span className="info">{errors.name}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Slug*</span>
                                
                                
                                
                                <Input
                                    type="text"
                                    name="slug"
                                    onChange={(e) => {
                                        this.state.entity.slug = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.slug ? this.state.entity?.slug : '' }
                                    
                                />
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.slug ? <span className="info">{errors.slug}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Bio</span>
                                
                                
                                
                                <Input
                                    type="textarea"
                                    name="bio"
                                    onChange={(e) => {
                                        this.state.entity.bio = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.bio ? this.state.entity?.bio : '' }
                                    
                                />
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.bio ? <span className="info">{errors.bio}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Name Origin</span>
                                
                                
                                
                                <Input
                                    type="textarea"
                                    name="name_origin"
                                    onChange={(e) => {
                                        this.state.entity.name_origin = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.name_origin ? this.state.entity?.name_origin : '' }
                                    
                                />
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.name_origin ? <span className="info">{errors.name_origin}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Main Image</span>
                                
                                
                                
                                <div>
                                <Input
                                    disabled
                                    type="text"
                                    name="main_image"
                                    onChange={(e) => {
                                        this.state.entity.main_image = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.main_image ? this.state.entity?.main_image : '' }                                    
                                />
                                <ImageUploading                                    
                                    value={ this.state.entity.main_image }
                                    onChange={(imageList, addUpdateIndex) => this.onImageChange(imageList, addUpdateIndex, "main_image")}
                                    onError={ (err) => {console.log(err)} }
                                    maxNumber={1}
                                    dataURLKey="data_url">
                                    {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemoveAll,
                                    onImageUpdate,
                                    onImageRemove,
                                    isDragging,
                                    dragProps,
                                    }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <Button
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        >
                                        Click or Drop here
                                        </Button>
                                        &nbsp; 
                                        { this.state.entity.main_image ?                                        
                                        <div className="image-item">
                                            <img src={this.state.entity.main_image} alt="" width="100" />
                                            <div className="image-item__btn-wrapper">                                                
                                            <Button className="btn-danger" onClick={() => {
                                                this.state.entity.main_image = undefined
                                                this.setState(this.state)
                                            }}>Remove</Button>
                                            </div>
                                        </div>
                                        : '' }
                                    </div>
                                    )}
                                </ImageUploading>
                                </div>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.main_image ? <span className="info">{errors.main_image}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>drag_type</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="drag_type"
                                    onChange={(e) => {
                                        this.state.entity.drag_type = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.drag_type ? this.state.entity?.drag_type : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="drag_queen">Drag Queen</option>
                                    
                                    <option value="drag_king">Drag King </option>
                                    
                                    <option value="bio_queen">Bio Queen</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.drag_type ? <span className="info">{errors.drag_type}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Main Drag Style</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="drag_style_primary"
                                    onChange={(e) => {
                                        this.state.entity.drag_style_primary = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.drag_style_primary ? this.state.entity?.drag_style_primary : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="pageant">Pageant</option>
                                    
                                    <option value="comedy">Comedy/Camp</option>
                                    
                                    <option value="goth">Goth</option>
                                    
                                    <option value="club_kid">Club Kid</option>
                                    
                                    <option value="look">Look</option>
                                    
                                    <option value="genderfuck">Genderfuck</option>
                                    
                                    <option value="fish">Fish</option>
                                    
                                    <option value="fluid">Fluid</option>
                                    
                                    <option value="trans">Trans</option>
                                    
                                    <option value="activessle">Activessle</option>
                                    
                                    <option value="tranimal">Tranimal</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.drag_style_primary ? <span className="info">{errors.drag_style_primary}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Seconday Drag Style</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="drag_style_secondary"
                                    onChange={(e) => {
                                        this.state.entity.drag_style_secondary = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.drag_style_secondary ? this.state.entity?.drag_style_secondary : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="pageant">Pageant</option>
                                    
                                    <option value="comedy">Comedy/Camp</option>
                                    
                                    <option value="goth">Goth</option>
                                    
                                    <option value="club_kid">Club Kid</option>
                                    
                                    <option value="look">Look</option>
                                    
                                    <option value="genderfuck">Genderfuck</option>
                                    
                                    <option value="fish">Fish</option>
                                    
                                    <option value="fluid">Fluid</option>
                                    
                                    <option value="trans">Trans</option>
                                    
                                    <option value="activessle">Activessle</option>
                                    
                                    <option value="tranimal">Tranimal</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.drag_style_secondary ? <span className="info">{errors.drag_style_secondary}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Third Drag Style</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="drag_style_tertiary"
                                    onChange={(e) => {
                                        this.state.entity.drag_style_tertiary = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.drag_style_tertiary ? this.state.entity?.drag_style_tertiary : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="pageant">Pageant</option>
                                    
                                    <option value="comedy">Comedy/Camp</option>
                                    
                                    <option value="goth">Goth</option>
                                    
                                    <option value="club_kid">Club Kid</option>
                                    
                                    <option value="look">Look</option>
                                    
                                    <option value="genderfuck">Genderfuck</option>
                                    
                                    <option value="fish">Fish</option>
                                    
                                    <option value="fluid">Fluid</option>
                                    
                                    <option value="trans">Trans</option>
                                    
                                    <option value="activessle">Activessle</option>
                                    
                                    <option value="tranimal">Tranimal</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.drag_style_tertiary ? <span className="info">{errors.drag_style_tertiary}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Entrance Quote</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
                                <div className="jsonFieldWrapper">
                                <div className="jsonFieldItems">
                                    { this.state.entity?.entrance_quote !== undefined && this.state.entity?.entrance_quote instanceof Array ? this.state.entity?.entrance_quote.map((el, i) => {
                                        return (<div key={JSON.stringify(el)} className="jsonFieldItem" 
                                            onClick={() => {
                                                this.props.popups.open(GPGModal, {
                                                        title: <b>Edit Entrance Quote</b>,
                                                        content:<DragEntranceQuoteForm 
                                                            entity={ el } 
                                                            onSubmit={(data) => {                                                                                                            
                                                                this.state.entity.entrance_quote[i] = data
                                                                this.setState(this.state)
                                                                this.props.popups.closeAll();                                                    
                                                            } 
                                                        }  />
                                                    }
                                                )
                                            } }
                                        >
                                            
                                            <div><span>season_id:</span> <b>{el.season_id}</b></div>
                                            
                                            <div><span>quote:</span> <b>{el.quote}</b></div>
                                            
                                        </div>)
                                    }) : undefined}
                                </div>
                                <Button onClick={() => {
                                    this.props.popups.open(GPGModal, {
                                                title: <b>Add Entrance Quote</b>,
                                                content: <DragEntranceQuoteForm 
                                                    entity={ {} } 
                                                    onSubmit={(data) => {                                                    
                                                        if (!this.state.entity.entrance_quote) {
                                                            this.state.entity.entrance_quote = []
                                                        }
                                                        this.state.entity.entrance_quote.push(data)
                                                        this.setState(this.state)
                                                        this.props.popups.closeAll();                                                    
                                                    } 
                                                }  />,
                                                onClose: (...params) => {
                                                }
                                                }); 
                                }}>Add</Button>
                                </div>
                                
                                
                                
            
                                { errors.entrance_quote ? <span className="info">{errors.entrance_quote}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Farewell Message</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
                                <div className="jsonFieldWrapper">
                                <div className="jsonFieldItems">
                                    { this.state.entity?.farewell_quote !== undefined && this.state.entity?.farewell_quote instanceof Array ? this.state.entity?.farewell_quote.map((el, i) => {
                                        return (<div key={JSON.stringify(el)} className="jsonFieldItem" 
                                            onClick={() => {
                                                this.props.popups.open(GPGModal, {
                                                        title: <b>Edit Farewell Message</b>,
                                                        content:<DragFarewellQuoteForm 
                                                            entity={ el } 
                                                            onSubmit={(data) => {                                                                                                            
                                                                this.state.entity.farewell_quote[i] = data
                                                                this.setState(this.state)
                                                                this.props.popups.closeAll();                                                    
                                                            } 
                                                        }  />
                                                    }
                                                )
                                            } }
                                        >
                                            
                                            <div><span>season_id:</span> <b>{el.season_id}</b></div>
                                            
                                            <div><span>quote:</span> <b>{el.quote}</b></div>
                                            
                                        </div>)
                                    }) : undefined}
                                </div>
                                <Button onClick={() => {
                                    this.props.popups.open(GPGModal, {
                                                title: <b>Add Farewell Message</b>,
                                                content: <DragFarewellQuoteForm 
                                                    entity={ {} } 
                                                    onSubmit={(data) => {                                                    
                                                        if (!this.state.entity.farewell_quote) {
                                                            this.state.entity.farewell_quote = []
                                                        }
                                                        this.state.entity.farewell_quote.push(data)
                                                        this.setState(this.state)
                                                        this.props.popups.closeAll();                                                    
                                                    } 
                                                }  />,
                                                onClose: (...params) => {
                                                }
                                                }); 
                                }}>Add</Button>
                                </div>
                                
                                
                                
            
                                { errors.farewell_quote ? <span className="info">{errors.farewell_quote}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Names</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
                                <div className="jsonFieldWrapper">
                                <div className="jsonFieldItems">
                                    { this.state.entity?.names !== undefined && this.state.entity?.names instanceof Array ? this.state.entity?.names.map((el, i) => {
                                        return (<div key={JSON.stringify(el)} className="jsonFieldItem" 
                                            onClick={() => {
                                                this.props.popups.open(GPGModal, {
                                                        title: <b>Edit Names</b>,
                                                        content:<DragNamesForm 
                                                            entity={ el } 
                                                            onSubmit={(data) => {                                                                                                            
                                                                this.state.entity.names[i] = data
                                                                this.setState(this.state)
                                                                this.props.popups.closeAll();                                                    
                                                            } 
                                                        }  />
                                                    }
                                                )
                                            } }
                                        >
                                            
                                            <div><span>Name Type:</span> <b>{el.type}</b></div>
                                            
                                            <div><span>Name:</span> <b>{el.value}</b></div>
                                            
                                        </div>)
                                    }) : undefined}
                                </div>
                                <Button onClick={() => {
                                    this.props.popups.open(GPGModal, {
                                                title: <b>Add Names</b>,
                                                content: <DragNamesForm 
                                                    entity={ {} } 
                                                    onSubmit={(data) => {                                                    
                                                        if (!this.state.entity.names) {
                                                            this.state.entity.names = []
                                                        }
                                                        this.state.entity.names.push(data)
                                                        this.setState(this.state)
                                                        this.props.popups.closeAll();                                                    
                                                    } 
                                                }  />,
                                                onClose: (...params) => {
                                                }
                                                }); 
                                }}>Add</Button>
                                </div>
                                
                                
                                
            
                                { errors.names ? <span className="info">{errors.names}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Links</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
                                <div className="jsonFieldWrapper">
                                <div className="jsonFieldItems">
                                    { this.state.entity?.links !== undefined && this.state.entity?.links instanceof Array ? this.state.entity?.links.map((el, i) => {
                                        return (<div key={JSON.stringify(el)} className="jsonFieldItem" 
                                            onClick={() => {
                                                this.props.popups.open(GPGModal, {
                                                        title: <b>Edit Links</b>,
                                                        content:<DragLinksForm 
                                                            entity={ el } 
                                                            onSubmit={(data) => {                                                                                                            
                                                                this.state.entity.links[i] = data
                                                                this.setState(this.state)
                                                                this.props.popups.closeAll();                                                    
                                                            } 
                                                        }  />
                                                    }
                                                )
                                            } }
                                        >
                                            
                                            <div><span>type:</span> <b>{el.type}</b></div>
                                            
                                            <div><span>link:</span> <b>{el.link}</b></div>
                                            
                                        </div>)
                                    }) : undefined}
                                </div>
                                <Button onClick={() => {
                                    this.props.popups.open(GPGModal, {
                                                title: <b>Add Links</b>,
                                                content: <DragLinksForm 
                                                    entity={ {} } 
                                                    onSubmit={(data) => {                                                    
                                                        if (!this.state.entity.links) {
                                                            this.state.entity.links = []
                                                        }
                                                        this.state.entity.links.push(data)
                                                        this.setState(this.state)
                                                        this.props.popups.closeAll();                                                    
                                                    } 
                                                }  />,
                                                onClose: (...params) => {
                                                }
                                                }); 
                                }}>Add</Button>
                                </div>
                                
                                
                                
            
                                { errors.links ? <span className="info">{errors.links}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Media</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
                                <div className="jsonFieldWrapper">
                                <div className="jsonFieldItems">
                                    { this.state.entity?.media !== undefined && this.state.entity?.media instanceof Array ? this.state.entity?.media.map((el, i) => {
                                        return (<div key={JSON.stringify(el)} className="jsonFieldItem" 
                                            onClick={() => {
                                                this.props.popups.open(GPGModal, {
                                                        title: <b>Edit Media</b>,
                                                        content:<DragMediaForm 
                                                            entity={ el } 
                                                            onSubmit={(data) => {                                                                                                            
                                                                this.state.entity.media[i] = data
                                                                this.setState(this.state)
                                                                this.props.popups.closeAll();                                                    
                                                            } 
                                                        }  />
                                                    }
                                                )
                                            } }
                                        >
                                            
                                            <div><span>type:</span> <b>{el.type}</b></div>
                                            
                                            <div><span>Media:</span> <b>{el.url}</b></div>
                                            
                                            <div><span>Description:</span> <b>{el.description}</b></div>
                                            
                                        </div>)
                                    }) : undefined}
                                </div>
                                <Button onClick={() => {
                                    this.props.popups.open(GPGModal, {
                                                title: <b>Add Media</b>,
                                                content: <DragMediaForm 
                                                    entity={ {} } 
                                                    onSubmit={(data) => {                                                    
                                                        if (!this.state.entity.media) {
                                                            this.state.entity.media = []
                                                        }
                                                        this.state.entity.media.push(data)
                                                        this.setState(this.state)
                                                        this.props.popups.closeAll();                                                    
                                                    } 
                                                }  />,
                                                onClose: (...params) => {
                                                }
                                                }); 
                                }}>Add</Button>
                                </div>
                                
                                
                                
            
                                { errors.media ? <span className="info">{errors.media}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Quotes</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
                                <div className="jsonFieldWrapper">
                                <div className="jsonFieldItems">
                                    { this.state.entity?.quotes !== undefined && this.state.entity?.quotes instanceof Array ? this.state.entity?.quotes.map((el, i) => {
                                        return (<div key={JSON.stringify(el)} className="jsonFieldItem" 
                                            onClick={() => {
                                                this.props.popups.open(GPGModal, {
                                                        title: <b>Edit Quotes</b>,
                                                        content:<DragQuotesForm 
                                                            entity={ el } 
                                                            onSubmit={(data) => {                                                                                                            
                                                                this.state.entity.quotes[i] = data
                                                                this.setState(this.state)
                                                                this.props.popups.closeAll();                                                    
                                                            } 
                                                        }  />
                                                    }
                                                )
                                            } }
                                        >
                                            
                                            <div><span>text:</span> <b>{el.text}</b></div>
                                            
                                            <div><span>date:</span> <b>{el.date}</b></div>
                                            
                                            <div><span>details:</span> <b>{el.details}</b></div>
                                            
                                        </div>)
                                    }) : undefined}
                                </div>
                                <Button onClick={() => {
                                    this.props.popups.open(GPGModal, {
                                                title: <b>Add Quotes</b>,
                                                content: <DragQuotesForm 
                                                    entity={ {} } 
                                                    onSubmit={(data) => {                                                    
                                                        if (!this.state.entity.quotes) {
                                                            this.state.entity.quotes = []
                                                        }
                                                        this.state.entity.quotes.push(data)
                                                        this.setState(this.state)
                                                        this.props.popups.closeAll();                                                    
                                                    } 
                                                }  />,
                                                onClose: (...params) => {
                                                }
                                                }); 
                                }}>Add</Button>
                                </div>
                                
                                
                                
            
                                { errors.quotes ? <span className="info">{errors.quotes}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Email</span>
                                
                                
                                
                                <Input
                                    type="text"
                                    name="email"
                                    onChange={(e) => {
                                        this.state.entity.email = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.email ? this.state.entity?.email : '' }
                                    
                                />
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.email ? <span className="info">{errors.email}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Locale</span>
                                
                                
                                
                                <Input
                                    type="text"
                                    name="locale"
                                    onChange={(e) => {
                                        this.state.entity.locale = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.locale ? this.state.entity?.locale : '' }
                                    
                                />
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.locale ? <span className="info">{errors.locale}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Birthdate*</span>
                                
                                
                                
                                                                    
                                
                                                                    
                                <Input
                                    type="date"
                                    name="birthdate"
                                    onChange={(e) => {
                                        this.state.entity.birthdate = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.birthdate ? this.state.entity?.birthdate : '' }                                  
                                    
                                />                                    
                                
                                
                                
                                

                                
                                
            
                                { errors.birthdate ? <span className="info">{errors.birthdate}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Ranking</span>
                                
                                
                                
                                                                    
                                
                                <Input
                                    type="number"
                                    name="ranking"
                                    onChange={(e) => {
                                        this.state.entity.ranking = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.ranking ? this.state.entity?.ranking : '' }
                                    
                                />
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.ranking ? <span className="info">{errors.ranking}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Gender</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="gender"
                                    onChange={(e) => {
                                        this.state.entity.gender = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.gender ? this.state.entity?.gender : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="trans_femme">Trans Femme</option>
                                    
                                    <option value="male">Male</option>
                                    
                                    <option value="female">Female</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.gender ? <span className="info">{errors.gender}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Pronouns In Drag</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="pronouns_in"
                                    onChange={(e) => {
                                        this.state.entity.pronouns_in = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.pronouns_in ? this.state.entity?.pronouns_in : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="he_him">He/Him</option>
                                    
                                    <option value="she_her">She/Her</option>
                                    
                                    <option value="them_they">They/Them</option>
                                    
                                    <option value="them_she">They/Them, She/Her</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.pronouns_in ? <span className="info">{errors.pronouns_in}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Pronouns out of Drag</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="pronouns_out"
                                    onChange={(e) => {
                                        this.state.entity.pronouns_out = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.pronouns_out ? this.state.entity?.pronouns_out : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="he_him">He/Him</option>
                                    
                                    <option value="she_her">She/Her</option>
                                    
                                    <option value="them_they">They/Them</option>
                                    
                                    <option value="them_she">They/Them, She/Her</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.pronouns_out ? <span className="info">{errors.pronouns_out}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Ethnicity</span>
                                
                                
                                
                                <Input
                                    type="text"
                                    name="ethnicity"
                                    onChange={(e) => {
                                        this.state.entity.ethnicity = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.ethnicity ? this.state.entity?.ethnicity : '' }
                                    
                                />
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.ethnicity ? <span className="info">{errors.ethnicity}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Status*</span>
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                <Input
                                    type="select"                                    
                                    name="status"
                                    onChange={(e) => {
                                        this.state.entity.status = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.status ? this.state.entity?.status : '' }
                                    
                                >
                                    <option value=""></option>
                                    
                                    <option value="enabled">Enabled</option>
                                    
                                    <option value="disabled">Disabled</option>
                                    
                                </Input>
                                
                                

                                
                                
            
                                { errors.status ? <span className="info">{errors.status}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Created*</span>
                                
                                
                                
                                                                    
                                
                                
                                                                    
                                <Input
                                    type="text"
                                    name="created_at"
                                    onChange={(e) => {
                                        this.state.entity.created_at = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.created_at ? this.state.entity?.created_at : '' }
                                    disabled
                                />                                    
                                
                                
                                

                                
                                
            
                                { errors.created_at ? <span className="info">{errors.created_at}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Updated*</span>
                                
                                
                                
                                                                    
                                
                                
                                                                    
                                <Input
                                    type="text"
                                    name="updated_at"
                                    onChange={(e) => {
                                        this.state.entity.updated_at = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.updated_at ? this.state.entity?.updated_at : '' }
                                    disabled
                                />                                    
                                
                                
                                

                                
                                
            
                                { errors.updated_at ? <span className="info">{errors.updated_at}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>User*</span>
                                
                                                                
                                ({this.state.user?.name}) &nbsp;
                                {this.state.entity.user_id = this.state.user?.id }
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.user_id ? <span className="info">{errors.user_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Location</span>
                                
                                
                                    
                                        <SearchEntity 
                                            entityIdentifier="location" 
                                            entityName="Location"
                                            onChange={(e) => {
                                                this.state.entity.location_id = e.value
                                                this.setState(this.state)                                                
                                            } }
                                            value={ {value:this.state.entity?.location_id, label:this.state.entity?.location_id ? "Loading..." : "Search"} }
                                             />
                                    
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.location_id ? <span className="info">{errors.location_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Home Town</span>
                                
                                
                                    
                                        <SearchEntity 
                                            entityIdentifier="location" 
                                            entityName="Location"
                                            onChange={(e) => {
                                                this.state.entity.home_town_id = e.value
                                                this.setState(this.state)                                                
                                            } }
                                            value={ {value:this.state.entity?.home_town_id, label:this.state.entity?.home_town_id ? "Loading..." : "Search"} }
                                             />
                                    
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.home_town_id ? <span className="info">{errors.home_town_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>Drag Mother</span>
                                
                                
                                    
                                        <SearchEntity 
                                            entityIdentifier="drag" 
                                            entityName="Drag"
                                            onChange={(e) => {
                                                this.state.entity.drag_mother_id = e.value
                                                this.setState(this.state)                                                
                                            } }
                                            value={ {value:this.state.entity?.drag_mother_id, label:this.state.entity?.drag_mother_id ? "Loading..." : "Search"} }
                                             />
                                    
                                
                                
                                
                                
                                                                    
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.drag_mother_id ? <span className="info">{errors.drag_mother_id}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>acting_score</span>
                                
                                
                                
                                                                    
                                
                                <Input
                                    type="number"
                                    name="acting_score"
                                    onChange={(e) => {
                                        this.state.entity.acting_score = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.acting_score ? this.state.entity?.acting_score : '' }
                                    
                                />
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.acting_score ? <span className="info">{errors.acting_score}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>comedy_score</span>
                                
                                
                                
                                                                    
                                
                                <Input
                                    type="number"
                                    name="comedy_score"
                                    onChange={(e) => {
                                        this.state.entity.comedy_score = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.comedy_score ? this.state.entity?.comedy_score : '' }
                                    
                                />
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.comedy_score ? <span className="info">{errors.comedy_score}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>dance_score</span>
                                
                                
                                
                                                                    
                                
                                <Input
                                    type="number"
                                    name="dance_score"
                                    onChange={(e) => {
                                        this.state.entity.dance_score = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.dance_score ? this.state.entity?.dance_score : '' }
                                    
                                />
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.dance_score ? <span className="info">{errors.dance_score}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>design_score</span>
                                
                                
                                
                                                                    
                                
                                <Input
                                    type="number"
                                    name="design_score"
                                    onChange={(e) => {
                                        this.state.entity.design_score = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.design_score ? this.state.entity?.design_score : '' }
                                    
                                />
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.design_score ? <span className="info">{errors.design_score}</span> : undefined }
                                </FormGroup>
                                                                
                                <FormGroup className="inputWrapper">
                                <span>lipsync_score</span>
                                
                                
                                
                                                                    
                                
                                <Input
                                    type="number"
                                    name="lipsync_score"
                                    onChange={(e) => {
                                        this.state.entity.lipsync_score = e.target.value
                                        this.setState(this.state)
                                    } }                                        
                                    onBlur={handleBlur}
                                    value={this.state.entity?.lipsync_score ? this.state.entity?.lipsync_score : '' }
                                    
                                />
                                
                                
                                
                                
                                

                                
                                
            
                                { errors.lipsync_score ? <span className="info">{errors.lipsync_score}</span> : undefined }
                                </FormGroup>
                                                                
                                
                                
                                <Button type="submit">
                                    Submit
                                </Button>
                            </form>
                        )}}
                        </Formik>                    
                </div>
        )
    }
}

export default DragForm