import React from 'react';
import Modal from 'react-modal';
import { MdClose } from "react-icons/md";

Modal.setAppElement('body');
export class GPGModal extends React.Component {
    

    close() {
        // `onClose` will close the modal and will call the callback defined in main.jsx
        this.props.onClose('param', 'param2', 'param3');
    }

    render() {
        // `isOpen` is managed only by 'PopupManager'
        const { isOpen } = this.props;

        return (
            <Modal isOpen={isOpen} shouldCloseOnEsc={true} >
               <h3>{this.props.title} <a style={ {float:"right", cursor:"pointer"} } onClick={() => this.close()}><MdClose /></a></h3>               
               {this.props.content}               
             </Modal>
        );
    }
}