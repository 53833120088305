
import './App.css';
import { Link } from "react-router-dom";
import Header from "./components/Header";
import {StringToColour} from "./Utils";


function App() {    
  return (
    <div id="app" className="App">
      <Header />
      
      <Link to="/user">
        <div className="entityList" style={{borderTopColor:StringToColour('user')}}>
          User
        </div>
      </Link>
      <Link to="/drag">
        <div className="entityList" style={{borderTopColor:StringToColour('drag')}}>
          Drag
        </div>
      </Link>
      <Link to="/location">
        <div className="entityList" style={{borderTopColor:StringToColour('location')}}>
          Location
        </div>
      </Link>
      <Link to="/user_has_drag">
        <div className="entityList" style={{borderTopColor:StringToColour('user_has_drag')}}>
          UserHasDrag
        </div>
      </Link>
      <Link to="/drag_show">
        <div className="entityList" style={{borderTopColor:StringToColour('drag_show')}}>
          DragShow
        </div>
      </Link>
      <Link to="/season">
        <div className="entityList" style={{borderTopColor:StringToColour('season')}}>
          Season
        </div>
      </Link>
      <Link to="/season_has_drag">
        <div className="entityList" style={{borderTopColor:StringToColour('season_has_drag')}}>
          SeasonHasDrag
        </div>
      </Link>
      <Link to="/episode">
        <div className="entityList" style={{borderTopColor:StringToColour('episode')}}>
          Episode
        </div>
      </Link>
      <Link to="/episode_has_drag">
        <div className="entityList" style={{borderTopColor:StringToColour('episode_has_drag')}}>
          EpisodeHasDrag
        </div>
      </Link>
      <Link to="/fantasy_season">
        <div className="entityList" style={{borderTopColor:StringToColour('fantasy_season')}}>
          FantasySeason
        </div>
      </Link>
      <Link to="/fanstasy_season_has_drag">
        <div className="entityList" style={{borderTopColor:StringToColour('fanstasy_season_has_drag')}}>
          FanstasySeasonHasDrag
        </div>
      </Link>        
    </div>
  );
}

export default App;
