import React from 'react'
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Client from '../client'
import {gql} from '@apollo/client';
import DragForm from '../forms/DragForm';
import { usePopupManager } from "react-popup-manager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";
import { MdCreate } from "react-icons/md";


class DragEdit extends React.Component {
    constructor(props) {
        super(props)        
        this.state = {
            pending:false,
            entity:{
                id: this.props.params.id,
            },
            notFound:false,
            images:[]
        }                             
    }

    componentDidMount() {        
        if (this.props.params.id !== undefined) {
            this.state.pending = true
            this.setState(this.state)
            this.fetchEntity();
        }
    }

    fetchEntity() {
        Client
            .query({
                query: gql`
                query DragByID($id: ID!) {
                    DragByID(id:$id){ 
                        id,
                        name,
                        slug,
                        bio,
                        name_origin,
                        main_image,
                        drag_type,
                        drag_style_primary,
                        drag_style_secondary,
                        drag_style_tertiary,
                        entrance_quote{
                            season_id,quote,
                        },
                        farewell_quote{
                            season_id,quote,
                        },
                        names{
                            type,value,
                        },
                        links{
                            type,link,
                        },
                        media{
                            type,url,description,
                        },
                        quotes{
                            text,date,details,
                        },
                        email,
                        locale,
                        birthdate,
                        ranking,
                        gender,
                        pronouns_in,
                        pronouns_out,
                        ethnicity,
                        status,
                        created_at,
                        updated_at,
                        user_id,
                        location_id,
                        home_town_id,
                        drag_mother_id,
                        acting_score,
                        comedy_score,
                        dance_score,
                        design_score,
                        lipsync_score,
                    }
                }
                `,
                variables:{
                    id:this.state.entity.id,                    
                }
            })
            .then((result) => {
                this.state.pending = false
                var data = result.data.DragByID
                if (data.length === 0) {
                    this.state.notFound = true
                    toast.error('Entity not found after insert',{
                        position: toast.POSITION.TOP_CENTER
                    });
                } else {
                    console.log(data[0])
                    this.state.entity = JSON.parse(JSON.stringify(data[0]))
                }                              
                this.setState(this.state)
            })
            .catch((err) => {
                toast.error('Error fetching entity',{
                        position: toast.POSITION.TOP_CENTER
                });
                console.error(err);
            });
    }

    upsertEntity() {
        Client
            .mutate({
                mutation: gql`
                mutation upsertDrag($input: DragInput!) {
                    upsertDrag(input:$input){ 
                        id
                    }
                }
                `,
                variables:{
                    input:{
                        id:(this.state.entity.id ? this.state.entity.id : ''),
                        name:this.state.entity.name ? this.state.entity.name : '',
                        slug:this.state.entity.slug ? this.state.entity.slug : '',
                        bio:this.state.entity.bio ? this.state.entity.bio : '',
                        name_origin:this.state.entity.name_origin ? this.state.entity.name_origin : '',
                        main_image:this.state.entity.main_image ? this.state.entity.main_image : '',
                        drag_type:this.state.entity.drag_type ? this.state.entity.drag_type : '',
                        drag_style_primary:this.state.entity.drag_style_primary ? this.state.entity.drag_style_primary : '',
                        drag_style_secondary:this.state.entity.drag_style_secondary ? this.state.entity.drag_style_secondary : '',
                        drag_style_tertiary:this.state.entity.drag_style_tertiary ? this.state.entity.drag_style_tertiary : '',
                        entrance_quote:this.state.entity.entrance_quote ? this.state.entity.entrance_quote : [],
                        farewell_quote:this.state.entity.farewell_quote ? this.state.entity.farewell_quote : [],
                        names:this.state.entity.names ? this.state.entity.names : [],
                        links:this.state.entity.links ? this.state.entity.links : [],
                        media:this.state.entity.media ? this.state.entity.media : [],
                        quotes:this.state.entity.quotes ? this.state.entity.quotes : [],
                        email:this.state.entity.email ? this.state.entity.email : '',
                        locale:this.state.entity.locale ? this.state.entity.locale : '',
                        birthdate:this.state.entity.birthdate ? this.state.entity.birthdate : '1999-09-09',
                        ranking:this.state.entity.ranking ? this.state.entity.ranking : 0.0,
                        gender:this.state.entity.gender ? this.state.entity.gender : '',
                        pronouns_in:this.state.entity.pronouns_in ? this.state.entity.pronouns_in : '',
                        pronouns_out:this.state.entity.pronouns_out ? this.state.entity.pronouns_out : '',
                        ethnicity:this.state.entity.ethnicity ? this.state.entity.ethnicity : '',
                        status:this.state.entity.status ? this.state.entity.status : '',
                        created_at:this.state.entity.created_at ? this.state.entity.created_at : '1999-09-09 09:09:09',
                        updated_at:this.state.entity.updated_at ? this.state.entity.updated_at : '1999-09-09 09:09:09',
                        user_id:this.state.entity.user_id ? this.state.entity.user_id : '',
                        location_id:this.state.entity.location_id ? this.state.entity.location_id : '',
                        home_town_id:this.state.entity.home_town_id ? this.state.entity.home_town_id : '',
                        drag_mother_id:this.state.entity.drag_mother_id ? this.state.entity.drag_mother_id : '',
                        acting_score:this.state.entity.acting_score ? this.state.entity.acting_score : 0.0,
                        comedy_score:this.state.entity.comedy_score ? this.state.entity.comedy_score : 0.0,
                        dance_score:this.state.entity.dance_score ? this.state.entity.dance_score : 0.0,
                        design_score:this.state.entity.design_score ? this.state.entity.design_score : 0.0,
                        lipsync_score:this.state.entity.lipsync_score ? this.state.entity.lipsync_score : 0.0,
                    }                    
                }
            })
            .then((result) => {
                var data = result.data.upsertDrag
                if (!data.id) {
                    this.state.notFound = true
                } else {                    
                    this.state.entity.id = data.id
                    window.history.replaceState(null, document.title, "/drag/edit/"+this.state.entity.id)
                }                 
                window.scrollTo(0, 0)
                toast.success(this.props.params.id == undefined ? 'Created Successfully' : 'Edited Successfully',{
                    position: toast.POSITION.TOP_CENTER
                });
                setTimeout(() => {
                    toast.dismiss()
                    window.location.reload(false);
                }, "500")
                
            })
            .catch((err) => {
                toast.error('Error upserting entity',{
                        position: toast.POSITION.TOP_CENTER
                });
                console.error(err);
            });
    }    

    render() {              
        return (            
           <div>
                <Header />  
                <div className="actionsBar">  
                    <h2 className="pageTitle">{this.props.params.id === undefined ? 'Create' : 'Edit'} Drag</h2>                                  
                    { this.props.params.id !== undefined ? 
                    <a onClick={() => {
                        window.history.replaceState(null, document.title, "/drag/edit/")
                        window.location.reload(false);
                    }} ><MdCreate /> Create New</a>
                    : undefined }
                </div>
                {this.state.pending ? <div className="formWrapper" style={ {textAlign:"center"} }>Loading...</div> : 
                    <DragForm {...this.props} entity={this.state.entity} onSubmit={(data) =>{
                        this.upsertEntity()                        
                    } } />
                }
                <ToastContainer />
            </div> 
        )
    }
}

export default (props) => <DragEdit {...props}
            params={useParams()} popups={usePopupManager()} />