import React from 'react'
import AsyncSelect from 'react-select/async';
import Client from '../client'
import {gql} from '@apollo/client';
import { toast } from 'react-toastify';

export const searchQueries = new Map();
export const queriesByID = new Map();
export const searchFields = new Map();

searchQueries.set("user", gql`
                query SearchUser($query: String!, $limit: Int, $offset: Int) {
                    SearchUser(query:$query, limit:$limit, offset:$offset){ 
                        id,
                        name,
                        lastname,
                        email,
                        password,
                        locale,
                        slug,
                        admin,
                        status,
                        created_at,
                        updated_at,
                        location_id,
                    }
                }
            `)
queriesByID.set("user", gql`
    query UserByID($id: ID!) {
                    UserByID(id:$id){ 
                        id,
                        name,
                        lastname,
                        email,
                        password,
                        locale,
                        slug,
                        admin,
                        status,
                        created_at,
                        updated_at,
                        location_id,
                    }
                }
    `
)
searchFields.set("user", (item) => { var res = ''; res = item.name;res = res.concat(' - ', item.lastname);res = res.concat(' - ', item.email);return res;})
searchQueries.set("drag", gql`
                query SearchDrag($query: String!, $limit: Int, $offset: Int) {
                    SearchDrag(query:$query, limit:$limit, offset:$offset){ 
                        id,
                        name,
                        slug,
                        bio,
                        name_origin,
                        main_image,
                        drag_type,
                        drag_style_primary,
                        drag_style_secondary,
                        drag_style_tertiary,
                        entrance_quote{
                            season_id,quote,
                        },
                        farewell_quote{
                            season_id,quote,
                        },
                        names{
                            type,value,
                        },
                        links{
                            type,link,
                        },
                        media{
                            type,url,description,
                        },
                        quotes{
                            text,date,details,
                        },
                        email,
                        locale,
                        birthdate,
                        ranking,
                        gender,
                        pronouns_in,
                        pronouns_out,
                        ethnicity,
                        status,
                        created_at,
                        updated_at,
                        user_id,
                        location_id,
                        home_town_id,
                        drag_mother_id,
                        acting_score,
                        comedy_score,
                        dance_score,
                        design_score,
                        lipsync_score,
                    }
                }
            `)
queriesByID.set("drag", gql`
    query DragByID($id: ID!) {
                    DragByID(id:$id){ 
                        id,
                        name,
                        slug,
                        bio,
                        name_origin,
                        main_image,
                        drag_type,
                        drag_style_primary,
                        drag_style_secondary,
                        drag_style_tertiary,
                        entrance_quote{
                            season_id,quote,
                        },
                        farewell_quote{
                            season_id,quote,
                        },
                        names{
                            type,value,
                        },
                        links{
                            type,link,
                        },
                        media{
                            type,url,description,
                        },
                        quotes{
                            text,date,details,
                        },
                        email,
                        locale,
                        birthdate,
                        ranking,
                        gender,
                        pronouns_in,
                        pronouns_out,
                        ethnicity,
                        status,
                        created_at,
                        updated_at,
                        user_id,
                        location_id,
                        home_town_id,
                        drag_mother_id,
                        acting_score,
                        comedy_score,
                        dance_score,
                        design_score,
                        lipsync_score,
                    }
                }
    `
)
searchFields.set("drag", (item) => { var res = ''; res = item.name;return res;})
searchQueries.set("location", gql`
                query SearchLocation($query: String!, $limit: Int, $offset: Int) {
                    SearchLocation(query:$query, limit:$limit, offset:$offset){ 
                        id,
                        slug,
                        city,
                        state,
                        country,
                        iso2,
                        main_image,
                        lat,
                        lng,
                        locale,
                        status,
                    }
                }
            `)
queriesByID.set("location", gql`
    query LocationByID($id: ID!) {
                    LocationByID(id:$id){ 
                        id,
                        slug,
                        city,
                        state,
                        country,
                        iso2,
                        main_image,
                        lat,
                        lng,
                        locale,
                        status,
                    }
                }
    `
)
searchFields.set("location", (item) => { var res = ''; res = item.city;res = res.concat(' - ', item.state);res = res.concat(' - ', item.country);res = res.concat(' - ', item.iso2);return res;})
searchQueries.set("user_has_drag", gql`
                query SearchUserHasDrag($query: String!, $limit: Int, $offset: Int) {
                    SearchUserHasDrag(query:$query, limit:$limit, offset:$offset){ 
                        id,
                        status,
                        created_at,
                        user_id,
                        drag_id,
                    }
                }
            `)
queriesByID.set("user_has_drag", gql`
    query UserHasDragByID($id: ID!) {
                    UserHasDragByID(id:$id){ 
                        id,
                        status,
                        created_at,
                        user_id,
                        drag_id,
                    }
                }
    `
)
searchFields.set("user_has_drag", (item) => { var res = ''; return res;})
searchQueries.set("drag_show", gql`
                query SearchDragShow($query: String!, $limit: Int, $offset: Int) {
                    SearchDragShow(query:$query, limit:$limit, offset:$offset){ 
                        id,
                        name,
                        slug,
                        main_image,
                        start_date,
                        end_date,
                        status,
                        created_at,
                        updated_at,
                        host_id,
                        user_id,
                    }
                }
            `)
queriesByID.set("drag_show", gql`
    query DragShowByID($id: ID!) {
                    DragShowByID(id:$id){ 
                        id,
                        name,
                        slug,
                        main_image,
                        start_date,
                        end_date,
                        status,
                        created_at,
                        updated_at,
                        host_id,
                        user_id,
                    }
                }
    `
)
searchFields.set("drag_show", (item) => { var res = ''; res = item.name;return res;})
searchQueries.set("season", gql`
                query SearchSeason($query: String!, $limit: Int, $offset: Int) {
                    SearchSeason(query:$query, limit:$limit, offset:$offset){ 
                        id,
                        name,
                        slug,
                        number,
                        main_image,
                        tagline,
                        description,
                        start_date,
                        end_date,
                        airing_details{
                            day,hour,channels,
                        },
                        status,
                        created_at,
                        updated_at,
                        drag_show_id,
                        winner_id,
                        second_winner_id,
                        congeniality_winner_id,
                        user_id,
                        playlist,
                    }
                }
            `)
queriesByID.set("season", gql`
    query SeasonByID($id: ID!) {
                    SeasonByID(id:$id){ 
                        id,
                        name,
                        slug,
                        number,
                        main_image,
                        tagline,
                        description,
                        start_date,
                        end_date,
                        airing_details{
                            day,hour,channels,
                        },
                        status,
                        created_at,
                        updated_at,
                        drag_show_id,
                        winner_id,
                        second_winner_id,
                        congeniality_winner_id,
                        user_id,
                        playlist,
                    }
                }
    `
)
searchFields.set("season", (item) => { var res = ''; res = item.name;res = res.concat(' - ', item.tagline);res = res.concat(' - ', item.playlist);return res;})
searchQueries.set("season_has_drag", gql`
                query SearchSeasonHasDrag($query: String!, $limit: Int, $offset: Int) {
                    SearchSeasonHasDrag(query:$query, limit:$limit, offset:$offset){ 
                        id,
                        status,
                        created_at,
                        season_id,
                        drag_id,
                        user_id,
                    }
                }
            `)
queriesByID.set("season_has_drag", gql`
    query SeasonHasDragByID($id: ID!) {
                    SeasonHasDragByID(id:$id){ 
                        id,
                        status,
                        created_at,
                        season_id,
                        drag_id,
                        user_id,
                    }
                }
    `
)
searchFields.set("season_has_drag", (item) => { var res = ''; return res;})
searchQueries.set("episode", gql`
                query SearchEpisode($query: String!, $limit: Int, $offset: Int) {
                    SearchEpisode(query:$query, limit:$limit, offset:$offset){ 
                        id,
                        name,
                        number,
                        slug,
                        description,
                        main_image,
                        air_date,
                        guest_judge,
                        episode_type,
                        mini_challenge_name,
                        maxi_challenge_name,
                        challenge_media{
                            name,description,video_url,image_url,
                        },
                        runway_theme,
                        lipsync{
                            song_name,song_artist,song_url,drag_id,drag2_id,drag3_id,drag4_id,drag5_id,drag6_id,order,video_url,no_looser,no_single_winner,
                        },
                        status,
                        created_at,
                        season_id,
                        mini_winner_id,
                        second_mini_winner_id,
                        third_mini_winner_id,
                        fourth_mini_winner_id,
                        fifth_mini_winner_id,
                        sixth_mini_winner_id,
                        winner_id,
                        second_winner_id,
                        third_winner_id,
                        fourth_winner_id,
                        fifth_winner_id,
                        sixth_winner_id,
                        eliminated_id,
                        second_eliminated_id,
                        elimination_msg,
                        user_id,
                    }
                }
            `)
queriesByID.set("episode", gql`
    query EpisodeByID($id: ID!) {
                    EpisodeByID(id:$id){ 
                        id,
                        name,
                        number,
                        slug,
                        description,
                        main_image,
                        air_date,
                        guest_judge,
                        episode_type,
                        mini_challenge_name,
                        maxi_challenge_name,
                        challenge_media{
                            name,description,video_url,image_url,
                        },
                        runway_theme,
                        lipsync{
                            song_name,song_artist,song_url,drag_id,drag2_id,drag3_id,drag4_id,drag5_id,drag6_id,order,video_url,no_looser,no_single_winner,
                        },
                        status,
                        created_at,
                        season_id,
                        mini_winner_id,
                        second_mini_winner_id,
                        third_mini_winner_id,
                        fourth_mini_winner_id,
                        fifth_mini_winner_id,
                        sixth_mini_winner_id,
                        winner_id,
                        second_winner_id,
                        third_winner_id,
                        fourth_winner_id,
                        fifth_winner_id,
                        sixth_winner_id,
                        eliminated_id,
                        second_eliminated_id,
                        elimination_msg,
                        user_id,
                    }
                }
    `
)
searchFields.set("episode", (item) => { var res = ''; res = item.name;res = res.concat(' - ', item.description);return res;})
searchQueries.set("episode_has_drag", gql`
                query SearchEpisodeHasDrag($query: String!, $limit: Int, $offset: Int) {
                    SearchEpisodeHasDrag(query:$query, limit:$limit, offset:$offset){ 
                        id,
                        drag_id,
                        episode_id,
                        position,
                        status,
                        created_at,
                        runway_look,
                        details,
                        other_look,
                        details_other,
                        third_look,
                        details_third,
                        fourth_look,
                        details_fourth,
                        fifth_look,
                        details_fifth,
                    }
                }
            `)
queriesByID.set("episode_has_drag", gql`
    query EpisodeHasDragByID($id: ID!) {
                    EpisodeHasDragByID(id:$id){ 
                        id,
                        drag_id,
                        episode_id,
                        position,
                        status,
                        created_at,
                        runway_look,
                        details,
                        other_look,
                        details_other,
                        third_look,
                        details_third,
                        fourth_look,
                        details_fourth,
                        fifth_look,
                        details_fifth,
                    }
                }
    `
)
searchFields.set("episode_has_drag", (item) => { var res = ''; return res;})

export class SearchEntity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            entityIdentifier: props.entityIdentifier,
            entityName: props.entityName,
            searchInput:"",
            value: props.value,            
        }   

        if (props.value?.value) {
            this.loadByID()
        }

        this.state.ref = React.createRef();
                 
    }

    loadByID(){
        if (!queriesByID.has(this.state.entityIdentifier)){
            console.log("query id not found : "+this.state.entityIdentifier)
            return 
        }

        Client
            .query({
                query: queriesByID.get(this.state.entityIdentifier),
                variables:{
                    id:this.state.value.value,                                       
                }
            })
            .then((result) => {  
                var data = result.data[this.state.entityName + "ByID"]
                if (data.length > 0) {
                    var fieldsFunc = searchFields.get(this.state.entityIdentifier)
                    this.state.value.label = fieldsFunc(data[0])
                    this.setState(this.state)
                }
            })

    }

    loadOptions(inputValue, callback){
        if (!searchQueries.has(this.state.entityIdentifier)){
            console.log("query not found : "+this.state.entityIdentifier)
            return 
        }
        Client
            .query({
                query: searchQueries.get(this.state.entityIdentifier),
                variables:{
                    query:this.state.searchInput,    
                    limit:10,
                    offset:0,                    
                }
            })
            .then((result) => {                
                var data = result.data["Search"+this.state.entityName]                
                var fieldsFunc = searchFields.get(this.state.entityIdentifier)
                var items = data.map(function(item) {
                    return {
                        value:item.id,
                        label:fieldsFunc(item)
                    };
                });
                callback(items)                                                                 
            })
            .catch((err) => {
                toast.error('Error searching entity',{
                        position: toast.POSITION.TOP_CENTER
                });
                console.error(err);
            });
    }

    handleInputChange(newValue){        
        this.state.searchInput = newValue
        this.setState(this.state)
        return newValue;
    };

    clearValue() {
        this.state.value = ""
        this.setState(this.state)
    }

    render() {
        return (
            <div>                
                <AsyncSelect
                    onChange={(data) => {
                        this.state.value = data
                        this.props.onChange(data, this.state.entityIdentifier)
                    } }
                    placeholder={this.props.placeholder}
                    isClearable={true}
                    escapeRemoves={true}                   
                    cacheOptions
                    loadOptions={(inputValue, callback) => this.loadOptions(inputValue, callback)}
                    defaultOptions
                    onInputChange={(newValue) => this.handleInputChange(newValue)}
                    value={this.state.value}                    
                    components={ {
                            IndicatorSeparator: () => null
                        } }
                    />
            </div>
        )
    }
}